export default {
    b_buy:'Buy',
    b_sell:'Sell',

    c_buy:'Buy',
    c_sell:'Sell',

    n_detail:'詳情信息',
    n_more:'公告',

    currency_exchange_rate:'幣種匯率',
    submit: '提交',
	amount: '金額',
	price: '價格',
	available: '可用',
	total: '合計',
	no_more: '沒有更多了',
	optional: '可選',
	remark: '備註',
	tips: '溫馨提示',
	copied: '已複製',
	petf: '請輸入此字段',
	MAX: '最大值',
    buyUp:"買漲",
    buyDown:"買跌",
    look:"查看",
    id_image_a:"請上傳身份證前照",
    id_image_b:"請上傳身份證後照",
    rfields:"必填項",
    
    imageLimit:"圖片文件大小不能超過5m",

    search_text:"請输入幣種（如：BTC）",
    registerS:'註冊成功',
    shouyi_t:'總收益率',

    // 新加
    required_address0:'登記地址',
    required_address:'請輸入登記地址',
    required_swift:'請輸入swift code',

    phoneVerified:'聯系方式',
    phoneVerifiederr:'請輸入聯系方式',

    //公賬驗證
    corporate_account_power:{
        h_1:"申請美元對公賬戶驗證",
        p_1:"美元入金需通過對公賬戶驗證才可以操作",
        btn:"提交驗證",
        h_2:"提示",
        p_2:"您已經提交美元對公賬戶驗證，請等待審核通過",
    },

    //交易方向
    onJump1:"現貨交易",
    onJump2:"合約交易",

    news_center:"新聞中心",

    middle_window:{
        Position:"位置",
        Price:"價格",
        Amount:"數量",
    },
    Confirm_order:{
        title:"確認訂單",
        h:"目前的價格",
        Due_time:"截止時間（秒）",
        Profit:"收益",
        label1:"投資金額（單筆投資不少於10個）",
        plc1:"輸入投資金額",
        Expected_profits:"預期利潤",
        Guaranteed_amount:"保證金額",
    },
    navbar: {
        home: '首頁',
        subscribe: '訂閱',
        fund: '區塊基金',
        help: '幫助',
        language: '語言',
        sign_in: '登錄',
        my_assets: '我的資產',
        profile: '個人資料',
        settings: '設置',
        logout: '退出登錄',

        new1:'新幣預告',
        new2:'行情',
        new3:'現貨',
        new4:'合約',
        new5:'法幣',
        // new_new:"新幣行情",
        new_new:"活躍幣種",
    },
    footer: {
        about_us: '關於我們',
        help_center: '幫助中心',
        fees: '手續費',
        user_agreement: '服務條款',
        contact_us: '聯繫我們',
        copyright: '所有版權',
        qualification: '資質信息',
    },
    base: {
        back: '返回',
        buy: '買入',
        sell: '賣出',
        no_more: '空列表',
        loading: '正在加載',
        sending: '正在發送',
        pending: '處理中',
        success: '成功',
        fail: '失敗',
        warning: '提示',
        danger: '警告',
        error: '錯誤',
        email: '郵箱',
        password: '密碼',
        old_pw: '舊密碼',
        new_pw: '新密碼',
        re_pw: '重輸密碼',
        pay_password: '交易密碼',
        submit: '提交',
        update: '更新',
        new: '新增',
        remark: '備註',
        voucher: '憑據',
        amount: '金額',
        enter_usdt_quantily: '請輸入USDT數量',
        enter_trade_password: '請輸入交易密碼',
        login_first: '請先登錄',
        state: '狀態',
        profit: '收益',
        coin: '幣',
        coin_profit: '幣收益',
        refresh: '刷新',
        total: '合計',
        deal: '操作'
    },
    home: {
        // banner: [
        //     require('@/assets/index/banner/1-1.jpg'),
        //     require('@/assets/index/banner/2-1.jpg'),
        //     require('@/assets/index/banner/3-1.jpg')
        // ],
        title1: 'Happycoinage',
        title2: '快速交易 , 安全資產',
        opened: '已開放',
        more: '更多',
        trade: '交易',
        pair: '交易對',
        price: '最新價格',
        change: '波動',
        high: '最高',
        low: '最低',
        finished: '成交',
        deal: '操作',
        // service_title: '立即開始您的加密貨幣之旅',
        service_title: '4 大 亮 點',
        service1: {
            title: '系統可靠',
            des1: '保證交易的安全，穩定，高效',
            des2: '世界頂尖人才的精英團隊'
        },
        service2: {
            title: '資金安全',
            des1: 'Https數據加密，身份驗證',
            des2: '冷錢包存儲，財務級別架構，多重安全保護'
        },
        service3: {
            title: '最好體驗',
            des1: '快速提款，多語言',
            des2: '高性能交易引擎，快速交易經驗'
        },
        service4: {
            title: '專業服務',
            des1: '專業的國際服務團隊',
            des2: '及時響應用戶問題'
        },
        guide_title:'新手指南',
    },
    fund: {
        banner1: {
            title1: 'USDT本位永續合約送福利',
            title2: '百萬豪禮等你抽'
        },
        banner2: {
            title1: '區塊鏈基金',
            title2: '成功推薦朋友可以獲得高額USDT'
        },
        banner3: {
            title1: 'XMR、IOTA、AR、XRT、',
            title2: 'USDT本位永續合約正式上線'
        },
        newest: '上新',
        my_participation: '我參與的',
        notify_message: '恭喜搶購成功',
        incentive_mechanism: '獎勵制度',
        locked_staking: '鎖倉賺幣',
        ongoing: '進行中',
        sold_out: '已結束',
        countdown_starts:'5分鐘後開始',
        profit: '收益',
        days: '天',
        user_limit: '參與用戶數量限制',
        applicants_user: '申請用戶數',
        buy_range: '參與金額範圍',
        read_more: '查看詳情',
        purchase: '參與',
        available: '可用',
        success_title: '提交成功',
        success_msg:'已提交成功，結束倒計時宣佈搶購結果，請查看我的參與情況',
        lock_up_the_rules: '鎖倉規則',
        lock_up_the_rules_1: '搶購結果在每局倒計時結束後公佈',
        lock_up_the_rules_2: '鎖倉時間到期後自動解鎖',
        lock_up_the_rules_3: 'TAOGE收益解釋：USDT總收益除以TAOGE實時價格，再轉化成TAOGE的數量，這就是TAOGE的總收益',
        lock_up_the_rules_4: '鎖定後獲得的USDT收入會轉到個人賬戶，TAOGE收入會被凍結到2021年8月30日，之後可以解凍自由交易',
        project_introduction: '項目介紹',
        project_introduction_content: '為什麼TAOGE區塊基金能有這麼高的收益？我們有穩健的理財量化收益，其中有USDT跨國際市場高頻對衝套利，由Cloud Cryptal Power機構及摩根大通前任CFO（Marianne Lake）參與直接管理。核心管理團隊在金融領域實戰經驗超過15年，非常瞭解對衝套利的風險和應對方案，已經形成相當成熟的量化對衝策略',
        lock_period: '錯倉週期',
        lock_start_time: '鎖倉開始時間',
        lock_end_time: '鎖倉結束時間',
        state: {
            checking: '審核中',
            locking: '鎖倉中',
            finished: '完成釋放',
            fail: '失敗'
        }
    },
    subscribe: {
        title1: '新幣訂閱',
        title2: '我們致力於幫助區塊鏈項目籌集資金，尋找支持者，並提出新穎有趣的好主意',
        new_currency: '新幣',
        my_participation: '我的訂閱',
        project_details: '項目詳情',
        subscription_ratio: '認購比例',
        issue_quota: '發行配額',
        participating: '已參與',
        lock_period: '鎖倉週期',
        months: '個月',
        thaw_interval: '解凍間隔',
        hours: '小時',
        available: '可用',
        purchase_limit: '參與限制',
        total: '合計',
        fee: '手續費',
        get_involved_now: '立即參與',
        end_date: '結束日期',
        project_des: '項目簡介',
        white_paper: '白皮書'
    },
    login: {
        title: '會員登錄',
        dont_have_an_account:'您還沒有會員賬號 ?',
        to_register: '去註冊',
        email: '郵箱地址',
        password: '登錄密碼',
        captcha: '圖形驗證碼',
        click_me_show: '點擊顯示',
        sign_in_now: '立即登錄',
        forget_password: '忘記密碼?',

        emailPhone:'郵箱或手機號碼',
        min6:'（至少6位）',
        rules1:'請輸入郵箱或者手機號碼',
        rules2:'請輸入最少6位密碼',
        rules3:'請輸入最少6位重復密碼',
        rules3_2:'兩次輸入密碼不一致',
        rules4:'請輸入邀請碼',
        rules5:'註冊需瀏覽並同意用戶協議',
        captcha_plc:'請輸入最少4位驗證碼',
    },
    register: {
        title: '會員註冊',
        phone: '電話號碼',
        re_password: '重複密碼',
        invitation_code: '邀請碼',
        email_captcha: '郵箱驗證碼',
        send_to_email: '發送到郵箱',
        already_have_an_account: '您已有一個會員賬號 ?',
        sign_in: '登錄'
    },
    forget: {
        title: '重設密碼',
        retrieve_now: '立即重設'
    },
    account: {
        title: '賬戶',
        recharge: '充值',
        profile: '個人資料',
        my_assets: '我的資產',
        withdraw: '提現',
        transactions: '現貨交易',
        micro_contract: '微合約交易',
        contract:'合約交易',
        inviting_and_awards: '邀請和返傭',
        settings: '設置',
        list_of_assets: '資產列表',
        available: '可用',
        frozen: '凍結',
        available_log: '可用記錄',
        event: '事件',
        time: '時間',
        event_type: {
            recharge: '充值',
            take: '扣除',
            withdraw: '提現',
            withdraw_fail: '提現失敗',
            trade_buy: '現貨買入',
            trade_sell: '現貨賣出',
            trade_success: '交易成功',
            trade_cancel: '交易撤單',
            subscribe: '訂閱',
            contract_buy: '微合約下單',
            contract_back: '微合約返回',
            contract_win: '微合約獲利',
            frozen: '凍結',
            thaw: '解凍',
            fund_buy: '區塊基金買入',
            fund_buy_fail: '區塊基金買入失敗',
            fund_back: '區塊基金本金返回',
            fund_income: '區塊基金收益',
            cfl1ua: '永久直推獎勵',
            cfl1u: '直推獎勵',
            cfl2u: '二級獎勵',
            cfl3u: '三級獎勵',
            exchange: '閃兌',

            // 新增
            new25:'轉出至合約賬戶',
            new26:'合約賬戶轉入',
            new27:'法幣充值',
            new28:'法幣提現',
            new29:'新幣認購',
            new30:'獎勵金',
            new31:'服務費',
        },
        email: '郵箱地址',
        verify: '認證',
        verify_state: {
            unsubmitted: '未提交',
            fail: '失敗',
            checking: '待審核',
            success: '完成'
        },
        verify_page:{
            rules1:'請輸入身份證名稱',
            rules2:'請輸入身份證號碼',
        },
        addressTitle:'冷錢包地址',
    },
    wallet: {
		tb: '總資產摺合',
		recharge: '充幣',
		withdraw: '提幣',
		exchange: '閃兌',
		in_order: '凍結',
		value: '摺合',
		pea: '請輸入金額',
		petp: '請輸入資金密碼',
		log_type: [
			'充值',
			'扣除',
			'提現',
			'提現失敗',
			'交易買入',
			'交易賣出',
			'交易成功',
			'交易撤單',
			'買幣',
			'合約交易買入',
			'合約交易平局',
			'合約交易勝局',
			'系統凍結',
			'系統解凍',
			'代幣基金買入',
			'代幣基金買入失敗',
			'代幣基金釋放本金',
			'代幣基金收益',
			'永久直推返傭',
			'直推返傭',
			'二級返傭',
			'三級返傭',
			'閃兌',
			'閃兌',
			'閃兌'
		]
	},
    recharge: {
		title: '充幣',
		cc: '點擊複製',
		uv: '上傳憑據',
		puv: '請上傳憑據',
		nor: '不開放充值',
		history: {
			title: '充幣記錄',
			checking: '待審核',
			success: '成功到賬',
			fail: '失敗'
		},
		tips: {
			USDT: [
				'請選擇網絡ERC20/OMNI/TRC20 ，將USDT提現到上述地址，否則資產將無法收回。 充值到上述地址後，需要確認整個網絡節點，12次網絡確認後確認賬戶，12次網絡確認後確認提現。',
				'最低充值金額200USDT，低於最低充值金額將不記入且無法退款。 您的充值地址不會經常變動，如有變動，您可以充值，我們會通過網站公告或郵件通知您。',
				'請確保計算機和瀏覽器安全，防止信息被篡改或洩露。'			
			],
			BTC: [
                '請選擇網絡ERC20/OMNI/TRC20 ，將BTC提現到上述地址，否則資產將無法收回。 充值到上述地址後，需要確認整個網絡節點，12次網絡確認後確認賬戶，12次網絡確認後確認提現。',
				'最低充值金額200USDT，低於最低充值金額將不記入且無法退款。 您的充值地址不會經常變動，如有變動，您可以充值，我們會通過網站公告或郵件通知您。',
				'請確保計算機和瀏覽器安全，防止信息被篡改或洩露。'	
			],
			ETH: [
                '請選擇網絡ERC20/OMNI/TRC20 ，將ETH提現到上述地址，否則資產將無法收回。 充值到上述地址後，需要確認整個網絡節點，12次網絡確認後確認賬戶，12次網絡確認後確認提現。',
				'最低充值金額200USDT，低於最低充值金額將不記入且無法退款。 您的充值地址不會經常變動，如有變動，您可以充值，我們會通過網站公告或郵件通知您。',
				'請確保計算機和瀏覽器安全，防止信息被篡改或洩露。'	
			],
			LTC: []
		}
	},
    withdraw: {
		title: '提幣',
		now: '不開放提幣',
        swa: '選擇錢包地址',
        awa: '添加錢包地址',
        nwa: '新的錢包地址',
		history: {
			title: '提幣記錄',
			checking: '待審核',
			success: '成功',
			fail: '失敗',
			cancel: '取消'
		}
	},
    exchange: {
		title: '閃兌',
		history: {
			title: '閃兌記錄'
		}
	},
    share: {
		title: '分享好友',
		cic: '複製邀請碼',
		t: '區塊鏈基金推廣分享獎勵機制',
		t1: '直接推廣代理',
		t1_1: '1. 直接推廣2個人以下獲得交易流水2%USDT獎勵',
		t1_2: '2. 直接推廣3個人以上（包含3個人）獲得交易流水4%USDT獎勵',
		t1_3: '3. 直接推廣5個人以上（包含5個人）獲得交易流水6%USDT獎勵',
		t1_4: '4. 直接推廣10個人以上（包含10個人）獲得交易流水8%USDT獎勵',
		t2: '二級代理',
		t2_1: '1. 二級代理人達到4人以下，獲得交易流水1%USDT獎勵',
		t2_2: '2. 二級代理達到4人（包含4個人），獲得交易流水2%USDT獎勵',
		t2_3: '3. 二級代理達到6人（包含6個人），獲得交易流水4%USDT獎勵',
		t3: '三級代理',
		t3_1: '1. 三級代理達到1人，獲得交易流水3%USDT獎勵',
		t3_2: '2. 三級代理達到4人（包含4個人），獲得交易流水6%USDT獎勵',
		t3_3: '3. 三級代理達到6人（包含6個人），獲得交易流水8%USDT獎勵',
		team: {
			title: '邀請喝返傭',
			ct1: '合計邀請人數',
			ct2: '今日邀請人數',
			ct3: '今日收益',
			ct4: '合計收益',
			ct5: '合計餘額',
			level_1: '一級', 
			level_2: '二級', 
			level_3: '三級',
		}
	},
    user: {
		email: '郵箱地址',
		email_captcha: '郵箱驗證碼',
		captcha: '圖片驗證碼',
		pwd: '密碼',
		confirm_pwd: '確認密碼',
		trade_pwd: '資金密碼',
		confirm_trade_pwd: '確認資金密碼',
		wa: '錢包地址',
		peea: '請輸入郵箱地址',
		pewa: '請輸入錢包地址',
		pec: '請輸入圖片驗證碼',
		peec: '請輸入郵箱驗證碼',
		cfii: '圖形驗證碼格式為字母和數字組成',
		ecfii: '郵箱驗證碼為數字組成',
		eafii: '郵箱地址格式錯誤',
		pep: '請輸入密碼',
		pecp: '請確認密碼',
		p_diff: '兩次輸入的密碼不一致',
		pfii: '密碼為字母和數字和符號組成',
		uff: '上傳文件失敗'
	},
    contact_about:{
        label1:'合約下單',
        top_text_h1:'凈值',
        top_text_h2:'保證金',
        top_text_h3:'持倉收益',
        available:'可用余額',
        fundswap:'資金互換',
        form:'從',
        to:'到',
        pair:'對幣',
        num:'數量',
        num_plc:'請輸入數量',
        all:'全部',
        cancel:'取消',
        confirm:'確定',
        margin:'保證金',
        margin1:'剩餘保證金',
        lever:'杠桿',
        please_choose:'請選擇',
        contract_order:'合約訂單',
        position_order:'持倉訂單',
        current_commission:'當前委托',
        switchBtn:'只看當前品種',
        liquidation:'平倉',
        table1:{
            pair:'對幣',
            type:'類型',
            income:'收益',
            rate_return:'收益率',
            deal:'交易',

            span_h1:'委托價',
            span_h2:'開倉價',
            span_h3:'保證金',
            span_h4:'最新價',
            span_h5:'委托時間',
            span_h6:'開倉時間',
            span_h7:'杠桿',

            span_h8:'平倉價',
            span_h9:'平倉時間',

            service_charge:'手續費',
        },
        table2:{
            type:'類型',
            price:'價格',
            amount:'數量',
            time:'時間',
            deal:'交易',

            service_charge:'服務費',
        },
        recharge_text1:"USDT現貨錢包",
        recharge_text2:"USDT合約錢包",
        error1:'請輸入數量且不能大於余額',
    },
    recharge_new:{
        verified_tip:'請先通過認證再使用充值功能',
        table_item1:'訂單號',
        table_item2:'狀態',
        table_item3:'類型',
        table_item4:'金額',
        table_item5:'憑證',
        table_item6:'時間',

        table_item7:'地址',

        table_state1:'提交中',
        table_state2:'成功',
        table_state3:'失敗',

        amount_mes:'純數字且最多保留2位小數',
        amount_mes2:'純數字且最多保留8位小數',
        message_img:'請上傳憑證圖片',
        message_password:'交易密碼不能為空',
        message_bal:'提現金額不能大於余額',

        table_item_new1:'轉入幣種',
        table_item_new2:'轉入數量',
        table_item_new3:'到賬幣種',
        table_item_new4:'到賬數量',
    },
    address:{
        title:'冷錢包地址',
        wallet_address:'請輸入冷錢包地址',
        wallet_list_title:'冷錢包列表',
        wallet_type:'類型',
        wallet_address_title:'冷錢包地址',
        wallet_opea:'操作',
        wallet_cancel:'取消',
        wallet_err:'錢包地址不能為空',
        wallet_tip:'限製10個冷錢包',
    },
    //現貨訂單列表
    transactions:{
        span1:'類型',
        span2:'對幣/本幣',
        span3:'委托價',
        span4:'數量',
        span5:'成交價',
        span6:'總價',
        span7:'時間',
        span8:'狀態',

        cancelBtn:'取消訂單',

        state_new1:'委托下單',
        state_new2:'用戶撤銷',
        state_new3:'成交訂單',
        state_new4:'系統撤銷',
    },
    micro_contract:{
        span1:'類型',
        span2:'對幣/本幣',
        span3:'數量',
        span4:'周期/收益率',
        span5:'收益',
        span6:'結算價格',
        span7:'結算時間',
        span8:'狀態',

        state1:'待處理',
        state2:'成功',
        state4:'失敗',
    },

    // 現貨詳情
    trading_index:{
        el_title:'現貨下單',
        el_title1:'委托訂單',
        el_switch1:'只看當前品種',
        message1:'買入總價不能超過余額',
        message2:'買入數量不能超過余額',

        // 新加市價限價
        tab_1:'市價委託',
        tab_2:'限價委託',
    },

    user_contract:{
        placeholder1:'請選擇',
        span1:'撤銷',
        span2:'用戶撤銷',
        span4:'已平倉',
        span5:'系統撤銷',
        rentext1:'全部幣種',
        rentext2:'全部類型',
        rentext3:'買入訂單',
        rentext4:'賣出訂單',
        rentext5:'全部狀態',
        rentext6:'委托下單',
        rentext7:'用戶撤銷',
        rentext8:'成交',
        rentext9:'平倉',
        rentext10:'系統撤銷',
    },

    //個人設置導航
    settings:{
        nav1:'KYC 認證',
        nav2:'登錄密碼',
        nav3:'交易密碼',
        nav4:'登錄記錄',
        ipAddress:"IP 地址",
        Date_time:"時間",
    },

    fiat_currency:{
        message:{
            tips:'提示',
            p_userToken:'請先登錄',
            p_userVerified:'請先通過實名認證',
            confirm:"確認",
            cancel:"取消",
        },
        onTab1:"台幣兌入",
        onTab2:"USDT快捷賣出",
        onTab3:"美元入金",
        onTab4:"法幣支付",
        span1:"交易商",
        span2:"數量",
        span3:"限額",
        span4:"單價",
        span5:"支付方式",
        span6:"交易",
        span7:"手續費",
        bank:"銀行卡",
        open:"購買USDT",

        fait_pay:{
            inp1:'充值金額（USDT）',
            plc1:'請輸入充值金額',
            inp2:'付款金額',
            plc2:'自動計算付款金額',
        },

        recycle:{
            inp1:"出售總數量",
            plc1:"出售總數量",
            inp2:"幣種",
            plc2:"請選擇幣種",
            inp3:"出售總金額",
            plc3:"出售總金額",
            inp4:"銀行卡",
            plc4:"請選擇銀行卡",
            submitForm:"提交",
            recycling_tips_title:"特別註意事項",
            recycling_tip1:"請使用平臺認證實名銀行卡，收款人姓名與平臺註冊姓名不一致無法回收。",
            recycling_tip2:"訂單高峰期，因處理訂單數量巨大，可能會造成回款時間較長，請耐心等待。",
            recycling_tip3:"USDT快捷賣出集中回款時間為12.00AM以及05:00PM（法定假日或非工作日除外）。",
        },
        public:{
            dt:"公賬信息",
            dd1:"收款人",
            dd2:"收款賬戶",
            dd3:"銀行名稱",
            dd4:"銀行地址",
            bankFn:"美元入金",
        },
        dialog1:{
            title:"購買",
            h5:"付款前必讀",
            p1:"重要：轉賬註意事項 請勿在轉賬時備註。",
            p2:"轉賬註意事項: 請勿在轉賬時備註《虛擬貨幣》《USDT》等與虛擬貨幣相關詞匯，以免您的銀行卡受凍結與轉賬攔截。",
            p3:"使用賬戶實名認證的的姓名其下銀行卡進行轉賬。",
            p4:"高峰時段放幣稍慢，請耐心等待。",
            span1:"數量",
            span2:"限額",
            span3:"單價",
            buynum:"請輸入購買數量",
            allbuyFn:"全部買入",
            pay_for:"實付款",
            comeFn:"確認",
        },
        dialog2:{
            title:"美元入金",
            label1:"金額",
            placeholder1:"請輸入金額",
            label2:"憑證",
            submitBank:"提交",
        },
        rules:{
            message1:"請輸入出售總數量",
            message2:"請選擇銀行卡",
            message3:"請輸入金額",
            message4:"請上傳憑證",
            err_message1:"請輸入購買數量",
            err_message2:"實付金額必須在限額區間之內",
            err_message3:"購買數量超過持有數量",
        },

        buy:{
            h2:"購買USDT",
            span1:"數量",
            span2:"單價",
            span3:"實付款",
            item2_h:"賣家收款方式",
            item2_p1:"請務必使用本人",
            item2_p2:"的支付方式向以下賬號自動轉賬",
            msg_h1:"收款銀行",
            msg_h2:"分行名稱",
            msg_h3:"銀行賬戶",
            msg_h4:"銀行代碼",
            msg_h5:"匯款備註",
            msg_h6:"上傳憑證",
            upload_text:"將文件拖到此處，或點擊上傳",
            upload_tip:"只能上傳圖片文件，且不超過8m",
            submitFn:"提交憑證",
            dealers_img:"交易商",
            flag_no:"已經是全部數據了",
            placeholder:"請輸入內容,按下'Enter'鍵提交",
            message1:"請輸入文字或者選擇圖片發送",
            message2:"發送失敗",
        },
    },

    index:{
		guide_box:{
			item1:"註冊",
			item2:"入金",
			item3:"交易",
			item4:"交易",
			item1_text:"註冊立即獲得10USDT",
			item2_text:"入金立即獲得20USDT",
			item3_text:"交易立即獲得50USDT",
			item4_text:"交易超過10000USDT立即獲得100USDT",
		},
    },

    Help_center:"幫助中心",
    account_fiat1:"銀行卡",
    account_fiat2:"法幣訂單",
    account_fiat3:"USDT快捷賣出訂單",

    account_fiat4:"新幣申購訂單",

    account_bank:{
        placeholder0:"請選擇幣種",

        placeholder1:"請輸入收款人",
        placeholder2:"請輸入收款銀行",
        placeholder3:"請輸入分行信息",
        placeholder4:"請輸入收款賬戶",
        placeholder5:"請輸入銀行代碼",
        submitForm:"提交",
        list_title:"銀行卡列表",
        list_span1:"收款人",
        list_span2:"銀行名稱",
        list_span3:"分行信息",
        list_span4:"收款賬戶",
        list_span5:"轉賬代碼",
        list_span6:"操作",
        onDel:"刪除",
        message1:"請輸入收款人",
        message2:"請輸入收款銀行",
        message3:"請輸入收款賬戶",
        err_message1:"銀行卡限製10張",
        onDel_h:"此操作將永久刪除該銀行卡, 是否繼續?",
        onDel_tips:"提示",
        confirmButtonText:"確定",
        cancelButtonText:"取消",
    },
    account_fiat:{
        title:"法幣訂單",
        select_placeholder:"請選擇狀態",
        select_val1:"進行中",
        select_val2:"已完成",
        select_val3:"訂單失敗",
        col1:"訂單號",
        col2:"金額",
        col3:"幣種",
        col4:"價格",
        col5:"法幣金額",
        col6:"狀態",
        status1:"進行中",
        status2:"已完成",
        status3:"訂單失敗",
        span_h1:"收款人姓名",
        span_h2:"收款銀行",
        span_h3:"分行名稱",
        span_h4:"收款賬戶",
        span_h5:"銀行代碼",
        span_h6:"銀行地址",
        span_h7:"匯款備註",
        span_h8:"創建時間",
        span_h9:"憑證",
    },
    account_recycle:{
        title:"USDT快捷賣出訂單",
        select:"請選擇狀態",
        select_val1:"進行中",
        select_val2:"已完成",
        select_val3:"訂單失敗",
        span1:"訂單號",
        span2:"金額",
        span3:"幣種",
        span4:"價格",
        span5:"法幣金額",
        span6:"狀態",
        status1:"進行中",
        status2:"已完成",
        status3:"訂單失敗",
        span_h1:"收款人姓名",
        span_h2:"收款銀行",
        span_h3:"分行名稱",
        span_h4:"收款賬戶",
        span_h5:"銀行代碼",
        span_h6:"銀行地址",
        span_h7:"匯款備註",
        span_h8:"創建時間",
        span_h9:"憑證",
    },
    settings_password:{
        placeholder1:"請輸入舊密碼",
        placeholder2:"請輸入新密碼",
        placeholder3:"請再次輸入新密碼",
        submit:"提交",
        message:"兩次輸入的密碼必須相同",
    },
    settings_paypassword:{
        placeholder1:"請輸入郵箱",
        placeholder2:"請輸入新的交易密碼",
        placeholder3:"請再次輸入新的交易密碼",
        placeholder4:"請輸入郵箱驗證碼",
        submit:"提交",
        send:"發送郵件",
        message:"兩次輸入的密碼必須相同",
    },
    account_details:{
        router:"合約錢包明細",
        span1:"類型",
        span2:"狀態",
        span3:"金額",
        span4:"時間",
        type0:"買漲",
        type1:"買跌",
        type2:"轉入",
        type3:"轉出",
        type4:"結算",
        type5:"手續費",
        type6:"系統補平",
        type7:"系統操作",
        state0:"待處理",
        state1:"完成",
        state2:"失敗",
    },

    r:{
        r1:"註冊需瀏覽並同意",
        r2:"用戶協議",
    },

    new_list:{
        h:"新幣行情",
        span2:"發行價",
        span3:"發行日期",
    },
    issue:{
        onOrderShow:"申購",
        issue_amount:"發行數量",
        use_issue_amount:"已認購量",
        close_day:"封閉天數",
        label:"申購數量",
        placeholder:"請輸入申購數量",
        Cancel:"取消",
        Submit:"提交",
        detail:"詳情介紹",
    },
    issue_order:{
        col1:"交易對",
        col2:"認購價",
        col3:"數量",
        col4:"合計金額",
        col5:"封閉天數",
        col6:"創建時間",
    },

    contract_shenqin:{
        text_notice1:'您未提交實名認証，請先提交實名認証',
        text_notice2:'您的實名認証失敗，請重新提交實名認証',
        text_notice3:'您的實名認証正在審核中',
        tips:'提示',
        confirmButtonText:'確認',
        cancelButtonText:'取消',
        // confirm_text:'合約交易需要資產達到10000USDT以上，請提交申請',
        // confirm_text:'合約交易需要資產達到10000USDT以上且滿3個月，請提交申請',
        confirm_text:'請提交合約申請，點擊確認完成申請，申請成功後即可交易',
        dataVerify_text:'您的合約交易權限申請正在處理中，請等待處理',
    },


    is_cancel_order:"確定要取消該訂單嗎？",
    cancel_order:"取消訂單",
    send_img:"上傳憑證",

    // 現貨新增市價限價
    youjia:"以當前最優價格交易",
    shijia_chao:"超出可用餘額",
    allBuyValueFnShijia_p:"金額",
    allSellValueFnShijia_p:"數量",

    xinbiyure:{
        issue_price:'發行價',
        issue_date:'發行日期',
        issue_amount:'發行數量',
        guimo:'募資規模',
        use_issue_amount:'累計申購額度',
        close_day:'凍結天數',
        published_date:'中簽公佈日期',
        showToast1:"超出可申購額度",
        showToast1_rengou:"超出剩餘認購額度",
        issue_plc:"請輸入申購金額",

        can_num_h:"可申購額度",
        orderFormData_amount1:"請輸入申購金額",
        can_num_h1:"剩餘認購額度",
        orderFormData_amount2:"請輸入認購金額",

        // 新增可用餘額usdt（現貨）
        can_num_h_u:"可用餘額",  
        showToast_u:"超出可用餘額",

        span1:"交易對",
        span2:"申購金額",
        span3:"狀態",
        span4:"中簽額度",
        span5:"剩餘認購額度",
        span6:"申購時間",
        span7:"操作",
        buyFn:"認購",
        state_arr1:"申購中",
        state_arr2:"中簽",
        state_arr3:"完成",
        state_arr4:"完成",
        amount_message:"請輸入認購金額",
    },

    recharge_manage:{
        title:'管理費錢包'
    },

    money_error:{
        text1:'不能為空',
        text2:'只能保留兩位小數',
    },
}