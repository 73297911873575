export default {
    b_buy:'Buy',
    b_sell:'Sell',

    c_buy:'Buy',
    c_sell:'Sell',

    n_detail:'Details',
    n_more:'Announcement',

    currency_exchange_rate:'Currency exchange rate',
    submit: 'Submit',
	amount: 'Amount',
	price: 'Price',
	available: 'Available',
	total: 'Total',
	no_more: 'No More',
	optional: 'Optional',
	remark: 'Remark',
	tips: 'Tips',
	copied: 'Copied',
	petf: 'Please enter this field',
	MAX: 'MAX',
    buyUp:"Buy up",
    buyDown:"Buy down",
    look:"View",
    id_image_a:"Please upload a picture of the front of your ID card",
    id_image_b:"Please upload a picture of the back of your ID card",
    rfields:"Required fields",

    imageLimit:"Image File Size Limit 5MB",

    search_text:"Please enter the currency (eg: BTC)",
    registerS:'Registration success',
    shouyi_t:'Total return',

    // 新加
    required_address0:'Registered address',
    required_address:'Please enter registered address',
    required_swift:'Please enter swift code',

    phoneVerified:'Contact information',
    phoneVerifiederr:'Please enter contact information',

    //公賬驗證
    corporate_account_power:{
        h_1:"Apply for USD to Corporate Account Verification",
        p_1:"U.S. dollar deposits need to be verified by the public account before they can be operated",
        btn:"Submit",
        h_2:"Tips",
        p_2:"You have submitted USD to public account verification, please wait for approval",
    },

    //交易方向
    onJump1:"Spot Trading",
    onJump2:"Contract Trading",

    news_center:"News Center",

    middle_window:{
        Position:"Position",
        Price:"Price",
        Amount:"Amount",
    },
    Confirm_order:{
        title:"Confirm order",
        h:"Current Price",
        Due_time:"Due time (seconds)",
        Profit:"Profit",
        label1:"Investment Amount (Single investment shall not be less than 10)",
        plc1:"Enter Investment Amount",
        Expected_profits:"Expected profits",
        Guaranteed_amount:"Guaranteed amount",
    },
    navbar: {
        home: 'HOME',
        subscribe: 'SUBSCRIBE',
        fund: 'FUND',
        help: 'HELP',
        language: 'LANGUAGE',
        sign_in: 'SIGN IN',
        my_assets: 'MY ASSETS',
        profile: 'Profile',
        settings: 'Settings',
        logout: 'Logout',

        new1:'New coin warm-up',
        // new2:'New coin',
        new2:'Market',
        new3:'Spot goods',
        new4:'Contract',
        new5:'Fiat currency',
        // new_new:"New coin market",
        new_new:"Active currency",
    },
    footer: {
        about_us: 'ABOUT US',
        help_center: 'HELP CENTER',
        fees: 'FEES',
        user_agreement: 'USER AGREEMENT',
        contact_us: 'CONTACT US',
        copyright: 'Copyright',
        qualification: 'QUALIFICATION INFORMATION',
    },
    base: {
        back: 'Back',
        buy: 'Buy',
        sell: 'Sell',
        no_more: 'No more',
        loading: 'Loading',
        sending: 'Sending',
        pending: 'Pending',
        success: 'Success',
        fail: 'Fail',
        warning: 'Warning',
        danger: 'Danger',
        error: 'Error',
        email: 'Email',
        password: 'Password',
        old_pw: 'Old-Passwd',
        new_pw: 'New-Passwd',
        re_pw: 'Re-Passwd',
        pay_password: 'Trade Password',
        submit: 'Submit',
        update: 'Update',
        new: 'New',
        remark: 'Remark',
        voucher: 'Voucher',
        amount: 'Amount',
        enter_usdt_quantily: 'Enter USDT quantily',
        enter_trade_password: 'Enter trade password',
        login_first: 'Login first',
        state: 'State',
        profit: 'Profit',
        coin: 'Coin',
        coin_profit: 'Coin Profit',
        refresh: 'Refresh',
        total: 'Total',
        deal: 'Deal'
    },
    home: {
        // banner: [
        //     require('@/assets/index/banner/1-2.jpg'),
        //     require('@/assets/index/banner/2-2.jpg'),
        //     require('@/assets/index/banner/3-2.jpg')
        // ],
        title1: 'Happycoinage',
        title2: 'A Global Cryptocurrency Leader Since 2017',
        opened: 'Opened',
        more: 'More',
        trade: 'Trade',
        pair: 'Pair',
        price: 'Price',
        change: 'Change',
        high: 'High',
        low: 'Low',
        finished: 'Finished',
        deal: 'Deal',
        // service_title: 'Start Your Cryptocurrency Journey Today',
        service_title: '4 big selling points',
        service1: {
            title: 'Reliable System',
            des1: 'Guarantee the safe, stable and efficient operation of the transaction',
            des2: 'Elite team of top talent in the world'
        },
        service2: {
            title: 'Safety of fund',
            des1: 'Https data encryption, identity verification',
            des2: 'Cold Wallet Storage, financial level architecture, multiple security protection'
        },
        service3: {
            title: 'The best experience',
            des1: 'Rapid withdrawals, Multilingual',
            des2: 'High performance trading engine, fast trading experience'
        },
        service4: {
            title: 'Professional services',
            des1: 'Professional international service teams',
            des2: 'Respond to user problems in a timely manner'
        },
        guide_title:"Beginner's Guide",
    },
    fund: {
        banner1: {
            title1: 'USDT Margined Swaps',
            title2: '1 million USDT Lucky Draw'
        },
        banner2: {
            title1: 'Blockchain Fund',
            title2: 'Successful recommendation of friends can earn a high USDT'
        },
        banner3: {
            title1: 'XMR、IOTA、AR、XRT、USDT',
            title2: 'Permanent contract trading goes live'
        },
        newest: 'Newest',
        my_participation: 'My participation',
        notify_message: 'Successfully participated',
        incentive_mechanism: 'Incentive mechanism',
        locked_staking: 'Locked Staking',
        ongoing: 'Ongoing',
        sold_out: 'Sole Out',
        countdown_starts:'Start in 5 min',
        profit: 'Profit',
        days: 'Day(s)',
        user_limit: 'Purchase user(s) limit',
        applicants_user: 'Applicants user(s)',
        buy_range: 'Purchase amount range',
        read_more: 'Read More',
        purchase: 'Purchase',
        available: 'Available',
        success_title: 'Submit successfully',
        success_msg:'Has been submitted successfully, the end of the countdown announced panic buying results.Please check my participation',
        lock_up_the_rules: 'Lock up the rules',
        lock_up_the_rules_1: 'Result of panic buying will be announced after the countdown of each round',
        lock_up_the_rules_2: 'Automatically unlock the lock when the lock time expires',
        lock_up_the_rules_3: 'Explanation of TAOGE income:USDT total revenue divided by TAOGE real-time price，And then the number of TAOGE\'s，So that\'s the total payoff of TAOGE',
        lock_up_the_rules_4: 'USDT income obtained after the lockup will be transferred to personal account, and TAOGE income will be frozen until Aug 30, 2021, after which free trading can be unfrozen',
        project_introduction: 'Project introduction',
        project_introduction_content: 'Why can TAOGE block funds have such high returns? We have steady quantitative wealth management returns, among which USDT has high-frequency hedging arbitrage across the international market, which is directly managed by Cloud Cryptal Power and Marianne Lake, the former CFO of jpmorgan chase. The core management team has more than 15 years of practical experience in the financial field. They are very familiar with the risks and solutions of hedging arbitrage and have formed a fairly mature quantitative hedging strategy',
        lock_period: 'Lock Period',
        lock_start_time: 'Lock Start Time',
        lock_end_time: 'Lock End Time',
        state: {
            checking: 'Checking',
            locking: 'Locking',
            finished: 'Finished',
            fail: 'Fail'
        }
    },
    subscribe: {
        title1: 'New coin preheating',
        title2: 'We are committed to helping blockchain projects raise funds, find supporters, and make fresh and interesting good ideas come',
        new_currency: 'New currency',
        my_participation: 'My participation',
        project_details: 'Project Details',
        subscription_ratio: 'Subscription Ratio',
        issue_quota: 'Issue quota',
        participating: 'Participating',
        lock_period: 'Lock period',
        months: 'Month(s)',
        thaw_interval: 'Thaw interval',
        hours: 'Hour(s)',
        available: 'Available',
        purchase_limit: 'Purchase Limit',
        total: 'Total',
        fee: 'Fee',
        get_involved_now: 'Get Involved Now',
        end_date: 'End Date',
        project_des: 'Project Description',
        white_paper: 'White Paper'
    },
    login: {
        title: 'SING IN',
        dont_have_an_account:'Don\'t have an account ?',
        to_register: 'To Rgister',
        email: 'Email',
        password: 'Password',
        captcha: 'Captcha',
        click_me_show: 'Click Me Show',
        sign_in_now: 'Sign in now',
        forget_password: 'Forget password ?',

        emailPhone:'Email or mobile phone number',
        min6:'(minimum 6 digits)',
        rules1:'Please enter your email or mobile number',
        rules2:'Please enter a password of at least 6 digits',
        rules3:'Please enter at least 6 digit duplicate password',
        rules3_2:'The passwords entered twice do not match',
        rules4:'Please enter the invitation code',
        rules5:'Registration requires viewing and agreeing to the User Agreement',
        captcha_plc:'Please enter at least 4 digit verification code',
    },
    register: {
        title: 'REGISTER',
        phone: 'Phone',
        re_password: 'Re-Password',
        invitation_code: 'Invitation Code',
        email_captcha: 'Email Captcha',
        send_to_email: 'Send to Email',
        already_have_an_account: 'Already have an account ?',
        sign_in: 'Sign in'
    },
    forget: {
        title: 'Retrieve the Password',
        retrieve_now: 'Retrieve now'
    },
    account: {
        title: 'Account',
        recharge: 'Recharge',
        profile: 'Profile',
        my_assets: 'My Assets',
        withdraw: 'Withdraw',
        transactions: 'Spot Trading',
        micro_contract: 'Micro contract transaction',
        contract:'Contract transaction',
        inviting_and_awards: 'Inviting & Awards',
        settings: 'Settings',
        list_of_assets: 'List of Assets',
        available: 'Available',
        frozen: 'Frozen',
        available_log: 'Available Log',
        event: 'Event',
        time: 'Time',
        event_type: {
            recharge: 'Recharge',
            take: 'Take',
            withdraw: 'Withdraw',
            withdraw_fail: 'Withdraw fail',
            trade_buy: 'Trade buy',
            trade_sell: 'Trade sell',
            trade_success: 'Trade success',
            trade_cancel: 'Trade cancel',
            subscribe: 'Subscribe',
            contract_buy: 'Micro contract order',
            contract_back: 'Micro contract return',
            contract_win: 'Micro contract profit',
            frozen: 'Frozen',
            thaw: 'Thaw',
            fund_buy: 'Fund buy',
            fund_buy_fail: 'Fund buy fail',
            fund_back: 'Fund back',
            fund_income: 'Fund income',
            cfl1ua: 'Commission From level 1 user (always)',
            cfl1u: 'Commission From level 1 user',
            cfl2u: 'Commission From level 2 user',
            cfl3u: 'Commission From level 3 user',
            exchange: 'Exchange',

            // 新增
            new25:'Transfer out to contract account',
            new26:'Contract account transfer',
            new27:'Fiat currency recharge',
            new28:'Fiat Withdrawal',
            new29:'New Currency Subscription',
            new30:'Incentive money',
            new31:'Service charge',
        },
        email: 'Email',
        verify: 'Verify',
        verify_state: {
            unsubmitted: 'Unsubmitted',
            fail: 'Fail',
            checking: 'Checking',
            success: 'Success'
        },
        verify_page:{
            rules1:'Please enter ID card name',
            rules2:'Please enter your ID number',
        },
        addressTitle:'Cold wallet address',
    },
    wallet: {
		tb: 'Total Balance',
		recharge: 'Recharge',
		withdraw: 'Withdraw',
		exchange: 'Exchange',
		in_order: 'In Order',
		value: 'Value',
		pea: 'Please enter Amount',
		petp: 'Please enter Trade Password',
		log_type: [
			'Recharge',
			'Take',
			'Withdraw',
			'Withdraw fail',
			'Trade buy',
			'Trade sell',
			'Trade success',
			'Trade cancel',
			'Subscribe',
			'Contract buy',
			'Contract back',
			'Contract win',
			'Frozen',
			'Thaw',
			'Fund buy',
			'Fund buy fail',
			'Fund back',
			'Fund income',
			'Commission from level 1 user (always)',
			'Commission from level 1 user',
			'Commission from level 2 user',
			'Commission from level 3 user',
			'Exchange',
			'Exchange',
			'Exchange'
		]
	},
    recharge: {
		title: 'Recharge',
		cc: 'Click Copy',
		uv: 'Upload Voucher',
		puv: 'Please Upload Voucher',
		nor: 'Not open Recharge',
		history: {
			title: 'Recharge Record',
			checking: 'Checking',
			success: 'Success',
			fail: 'Fail'
		},
		tips: {
			USDT: [
				'Please select network ERC20/OMNI/TRC20 and withdraw USDT to the above address, otherwise the assets will not be recovered. After you recharge to the above address, you need to confim the entire network node, after 12 times network confimation to confrim to the account, after 12 times network confimation to confirm withdrawal.',
				'Minimum recharge amount 200USDT, recharge less than the minimum amount will not be credited and cannot be refunded. Your recharge address will not change frequently, you can recharge it if there is a change, we will ty to notify you via webite announcement or email.',
				'Please make sure that the computer and browser are safe to prevent nformation from being tampered with or leaked.'
			],
			BTC: [
				'Please select network ERC20/OMNI/TRC20 and withdraw BTC to the above address, otherwise the assets will not be recovered. After you recharge to the above address, you need to confim the entire network node, after 12 times network confimation to confrim to the account, after 12 times network confimation to confirm withdrawal.',
				'Minimum recharge amount 200USDT, recharge less than the minimum amount will not be credited and cannot be refunded. Your recharge address will not change frequently, you can recharge it if there is a change, we will ty to notify you via webite announcement or email.',
				'Please make sure that the computer and browser are safe to prevent nformation from being tampered with or leaked.'
			],
			ETH: [
				'Please select network ERC20/OMNI/TRC20 and withdraw ETH to the above address, otherwise the assets will not be recovered. After you recharge to the above address, you need to confim the entire network node, after 12 times network confimation to confrim to the account, after 12 times network confimation to confirm withdrawal.',
				'Minimum recharge amount 200USDT, recharge less than the minimum amount will not be credited and cannot be refunded. Your recharge address will not change frequently, you can recharge it if there is a change, we will ty to notify you via webite announcement or email.',
				'Please make sure that the computer and browser are safe to prevent nformation from being tampered with or leaked.'
			],
			LTC: []
		}
	},
    withdraw: {
		title: 'Withdraw',
		now: 'Not open Withdraw',
        swa: 'Select Wallet Address',
        awa: 'Add Wallet Address',
        nwa: 'New Wallet Address',
		history: {
			title: 'Withdraw Record',
			checking: 'Checking',
			success: 'Success',
			fail: 'Fail',
			cancel: 'Cancel'
		}
	},
    exchange: {
		title: 'Exchange',
		history: {
			title: 'Exchange Record',
		}
	},
    share: {
		title: 'Share',
		cic: 'Copy Invitation Code',
		t: 'Incentive mechanism of Blockchain fund promotion and sharing',
		t1: 'Direct promotion agent',
		t1_1: '1. 2% USDT of transaction flow will be rewarded when the number of directly promotion agents is less than 2 people',
		t1_2: '2. 4% USDT of transaction flow will be rewarded when the number of directly promotion agents is more than 3 people (including 3 people)',
		t1_3: '3. 6% USDT of transaction flow will be rewarded when the number of directly promotion agents is more than 5 people (including 5 people)',
		t1_4: '4. 8% USDT of transaction flow will be rewarded when the number of directly promotion agents is more than 10 people (including 10 people)',
		t2: 'Secondary agent',
		t2_1: '1. 1% USDT of transaction flow will be rewarded when the number of secondary agents is less than 4 people',
		t2_2: '2. 2% USDT of transaction flow will be rewarded when the number of secondary agents is more than 4 people（including 4）',
		t2_3: '3. 4% USDT of transaction flow will be rewarded when the number of secondary agents is more than 6 people（including 6）',
		t3: 'Third-level agent',
		t3_1: '1. 3% USDT of transaction flow will be rewarded when the number of third-level agent is one people',
		t3_2: '2. 6% USDT of transaction flow will be rewarded when the number of third-level agents is more than 4 people（including 4）',
		t3_3: '3. 8% USDT of transaction flow will be rewarded when the number of third-level agents is more than 6 people（including 6）',
		team: {
			title: 'Invited & Awards',
			ct1: 'Total invited user(s)',
			ct2: 'Today new invited user(s)',
			ct3: 'Today income',
			ct4: 'Total income',
			ct5: 'Total balance',
			level_1: 'Level 1', 
			level_2: 'Level 2', 
			level_3: 'Level 3',
		}
	},
    user: {
		email: 'Email',
		email_captcha: 'Email Captcha',
		captcha: 'Captcha',
		pwd: 'Password',
		confirm_pwd: 'Confirm Password',
		trade_pwd: 'Trade Password',
		confirm_trade_pwd: 'Confirm Trade Password',
		wa: 'Wallet Address',
		peea: 'Please enter Email Address',
		pewa: 'Please Enter Wallet Address',
		pec: 'Please enter Captcha',
		peec: 'Please enter Email Captcha',
		cfii: 'The Captcha only allows letters and numbers',
		ecfii: 'The Email Captcha only allows numbers',
		eafii: 'Email Address format is incorrect',
		pep: 'Please enter Password',
		pecp: 'Please enter Confirm Password',
		p_diff: 'The two passwords entered are inconsistent',
		pfii: 'The password only allows letters and numbers',
		uff: 'Upload File Fail'
	},
    contact_about:{
        label1:'Contract order',
        top_text_h1:'Net worth',
        top_text_h2:'Margin',
        top_text_h3:'Position income',
        available:'Available',
        fundswap:'Fund swap',
        form:'Form',
        to:'To',
        pair:'Pair',
        num:'Amount',
        num_plc:'Please enter the quantity',
        all:'All',
        cancel:'Cancel',
        confirm:'Confirm',
        margin:'Margin',
        margin1:'Remaining margin',
        lever:'Lever',
        please_choose:'Please choose',
        contract_order:'Contract order',
        position_order:'Position order',
        current_commission:'Current commission',
        switchBtn:'Just look at the current variety',
        liquidation:'Liquidation',
        table1:{
            pair:'Pair',
            type:'Type',
            income:'Income',
            rate_return:'Rate of return',
            deal:'Deal',

            span_h1:'Commission price',
            span_h2:'Opening price',
            span_h3:'Margin',
            span_h4:'Latest price',
            span_h5:'Commission time',
            span_h6:'Open time',
            span_h7:'Lever',

            span_h8:'Closing price',
            span_h9:'Closing time',

            service_charge:'Service charge',
        },
        table2:{
            type:'Type',
            price:'Price',
            amount:'Amount',
            time:'Time',
            deal:'Deal',

            service_charge:'service charge',
        },
        recharge_text1:"USDT spot wallet",
        recharge_text2:"USDT contract wallet",
        error1:'Please enter the quantity and cannot be greater than the balance',
    },
    recharge_new:{
        verified_tip:'Please pass the authentication before using the top-up function',
        table_item1:'Order number',
        table_item2:'State',
        table_item3:'Type',
        table_item4:'Amount',
        table_item5:'Certificate',
        table_item6:'Time',

        table_item7:'Address',

        table_state1:'Submitting',
        table_state2:'Success',
        table_state3:'Fail',

        amount_mes:'Pure numbers and up to 2 decimal places',
        amount_mes2:'Pure numbers and up to 8 decimal places',
        message_img:'Please upload a voucher picture',
        message_password:'Transaction password cannot be empty',
        message_bal:'The withdrawal amount cannot be greater than the balance',

        table_item_new1:'Transfer-in currency',
        table_item_new2:'Transfer-in quantity',
        table_item_new3:'Currency of receipt',
        table_item_new4:'Received quantity',
    },
    address:{
        title:'Cold wallet address',
        wallet_address:'Please enter the cold wallet address',
        wallet_list_title:'List of cold wallets',
        wallet_type:'Type',
        wallet_address_title:'Cold wallet address',
        wallet_opea:'Operate',
        wallet_cancel:'Cancel',
        wallet_err:'Wallet address cannot be empty',
        wallet_tip:'Limit 10 cold wallets',
    },
    //現貨訂單列表
    transactions:{
        span1:'Type',
        span2:'Pair/Coin',
        span3:'Commission',
        span4:'Amount',
        span5:'Final price',
        span6:'Total price',
        span7:'Time',
        span8:'State',

        cancelBtn:'Cancel',

        state_new1:'Entrust an order',
        state_new2:'User cancellation',
        state_new3:'Transaction order',
        state_new4:'System cancellation',
    },
    micro_contract:{
        span1:'Type',
        span2:'Pair/Coin',
        span3:'Amount',
        span4:'Due Time/Profit(%)',
        span5:'Profit',
        span6:'Price',
        span7:'Time',
        span8:'State',

        state1:'Pending',
        state2:'Success',
        state4:'Fail',
    },

    // 現貨詳情
    trading_index:{
        el_title:'Spot orders',
        el_title1:'Consignment order',
        el_switch1:'Just look at the current variety',
        message1:'The total purchase price cannot exceed the balance',
        message2:'The purchase quantity cannot exceed the balance',

        // 新加市價限價
        tab_1:'Market order',
        tab_2:'Limit order',
    },

    user_contract:{
        placeholder1:'Please choose',
        span1:'Cancel',
        span2:'User cancellation',
        span4:'Closed',
        span5:'System cancellation',
        rentext1:'All currencies',
        rentext2:'All types',
        rentext3:'Buy',
        rentext4:'Sell',
        rentext5:'All status',
        rentext6:'Entrust an order',
        rentext7:'User cancellation',
        rentext8:'Make a deal',
        rentext9:'Liquidation',
        rentext10:'System cancellation',
    },

    //個人設置導航
    settings:{
        nav1:'KYC Verify',
        nav2:'Login Password',
        nav3:'Trade Password',
        nav4:'Login logs',
        ipAddress:"IP address",
        Date_time:"Date time",
    },

    fiat_currency:{
        message:{
            tips:'Tips',
            p_userToken:'Please log in first',
            p_userVerified:'Please pass real-name authentication first',
            confirm:"Confirm",
            cancel:"Cancel",
        },
        onTab1:"Optional transaction",
        onTab2:"USDT quick sell",
        onTab3:"U.S. dollar deposit",
        onTab4:"Legal currency payment",
        span1:"Dealers",
        span2:"Amount",
        span3:"Limit",
        span4:"Price",
        span5:"Payment method",
        span6:"Trade",
        span7:"Handling fee",
        bank:"Bank card",
        open:"Buy USDT",

        fait_pay:{
            inp1:'Recharge amount（USDT）',
            plc1:'Please enter the recharge amount',
            inp2:'Payment amount',
            plc2:'Automatically calculate payment amount',
        },

        recycle:{
            inp1:"Total quantity sold",
            plc1:"Total quantity sold",
            inp2:"Currency",
            plc2:"Please select a currency",
            inp3:"Total amount sold",
            plc3:"Total amount sold",
            inp4:"Bank card",
            plc4:"Please select a bank card",
            submitForm:"Submit",
            recycling_tips_title:"Special attention items",
            recycling_tip1:"Please use the platform to authenticate the real-name bank card. The payee's name is inconsistent with the platform's registered name and cannot be recovered.",
            recycling_tip2:"During the peak order period, due to the huge number of orders processed, it may take a long time to collect the payment, please be patient.",
            recycling_tip3:"The centralized collection time of USDT express sale is 12.00AM and 05:00PM (except statutory holidays or non-working days).",
        },
        public:{
            dt:"Public account information",
            dd1:"Payee",
            dd2:"Accounts receivable",
            dd3:"Bank name",
            dd4:"Bank address",
            bankFn:"U.S. dollar deposit",
        },
        dialog1:{
            title:"Buy",
            h5:"Must read before paying",
            p1:"Important: Notes on Transfers Please do not make notes when transferring money.",
            p2:'Notes on transfer: Please do not remark "virtual currency", "USDT" and other words related to virtual currency when transferring, so as to avoid your bank card being frozen and transfer intercepted.',
            p3:"Use the bank card under the name of the real-name authentication of the account to transfer money.",
            p4:"During peak hours, the release of coins is slightly slower, please be patient.",
            span1:"Amount",
            span2:"Limit",
            span3:"Price",
            buynum:"Please enter the purchase quantity",
            allbuyFn:"Buy all",
            pay_for:"Real payment",
            comeFn:"Confirm",
        },
        dialog2:{
            title:"U.S. dollar deposit",
            label1:"Amount",
            placeholder1:"Please enter the amount",
            label2:"Certificate",
            submitBank:"Submit",
        },
        rules:{
            message1:"Please enter the total quantity sold",
            message2:"Please select a bank card",
            message3:"Please enter the amount",
            message4:"Please upload credentials",
            err_message1:"Please enter the purchase quantity",
            err_message2:"The actual payment amount must be within the limit range",
            err_message3:"The number of purchases exceeds the number of holdings",
        },

        buy:{
            h2:"Buy USDT",
            span1:"Amount",
            span2:"Price",
            span3:"Real payment",
            item2_h:"Seller's payment method",
            item2_p1:"Please use yourself",
            item2_p2:"'s payment method is automatically transferred to the following account",
            msg_h1:"Beneficiary Bank",
            msg_h2:"Branch name",
            msg_h3:"Bank accounts",
            msg_h4:"Bank Code",
            msg_h5:"Remittance Notes",
            msg_h6:"Upload certificate",
            upload_text:"Drag files here, or click Upload",
            upload_tip:"Only image files can be uploaded, and no more than 8m",
            submitFn:"Submit credentials",
            dealers_img:"Dealers",
            flag_no:"It's all data",
            placeholder:"Please enter the content and press the 'Enter' key to submit",
            message1:"Please enter text or select an image to send",
            message2:"Failed to send",
        },
    },

    index:{
		guide_box:{
			item1:"Register",
			item2:"Deposit",
			item3:"Trade",
			item4:"Trade",
			item1_text:"Sign up to get 10USDT now",
			item2_text:"Deposit and get 20USDT instantly",
			item3_text:"Trade to get 50USDT now",
			item4_text:"Get 100USDT instantly when you trade over 10,000USDT",
		},
    },

    Help_center:"Help Center",
    account_fiat1:"Bank card",
    account_fiat2:"Fiat Order",
    account_fiat3:"USDT Express Sell Order",

    account_fiat4:"New currency purchase order",

    account_bank:{
        placeholder1:"Please enter payee",
        placeholder2:"Please enter the beneficiary bank",
        placeholder3:"Please enter branch information",
        placeholder4:"Please enter the receiving account",
        placeholder5:"Please enter bank code",
        submitForm:"Submit",
        list_title:"List of bank cards",
        list_span1:"Payee",
        list_span2:"Bank name",
        list_span3:"Branch Information",
        list_span4:"Accounts receivable",
        list_span5:"Transfer code",
        list_span6:"Operate",
        onDel:"Delete",
        message1:"Please enter payee",
        message2:"Please enter the beneficiary bank",
        message3:"Please enter the receiving account",
        err_message1:"Bank card limit of 10",
        onDel_h:"This operation will permanently delete the card, do you want to continue?",
        onDel_tips:"Tips",
        confirmButtonText:"Confirm",
        cancelButtonText:"Cancel",
    },
    account_fiat:{
        title:"Fiat Order",
        select_placeholder:"Please select a status",
        select_val1:"In progress",
        select_val2:"Completed",
        select_val3:"Order failed",
        col1:"Order number",
        col2:"Amount",
        col3:"Currency",
        col4:"Price",
        col5:"Fiat Amount",
        col6:"Status",
        status1:"In progress",
        status2:"Completed",
        status3:"Order failed",
        span_h1:"Payee Name",
        span_h2:"Beneficiary Bank",
        span_h3:"Branch name",
        span_h4:"Accounts receivable",
        span_h5:"Bank Code",
        span_h6:"Bank address",
        span_h7:"Remittance Notes",
        span_h8:"Creation time",
        span_h9:"Certificate",
    },
    account_recycle:{
        title:"USDT Express Sell Order",
        select:"Please select a status",
        select_val1:"In progress",
        select_val2:"Completed",
        select_val3:"Order failed",
        span1:"Order number",
        span2:"Amount",
        span3:"Currency",
        span4:"Price",
        span5:"Fiat Amount",
        span6:"Status",
        status1:"In progress",
        status2:"Completed",
        status3:"Order failed",
        span_h1:"Payee Name",
        span_h2:"Beneficiary Bank",
        span_h3:"Branch name",
        span_h4:"Accounts receivable",
        span_h5:"Bank Code",
        span_h6:"Bank address",
        span_h7:"Remittance Notes",
        span_h8:"Creation time",
        span_h9:"Certificate",
    },
    settings_password:{
        placeholder1:"Please enter old password",
        placeholder2:"Please enter a new password",
        placeholder3:"Please enter new password again",
        submit:"Submit",
        message:"The password entered twice must be the same",
    },
    settings_paypassword:{
        placeholder1:"Please input your email",
        placeholder2:"Please enter a new transaction password",
        placeholder3:"Please enter a new transaction password again",
        placeholder4:"Please enter email verification code",
        submit:"Submit",
        send:"Send email",
        message:"The password entered twice must be the same",
    },
    account_details:{
        router:"Contract wallet details",
        span1:"Type",
        span2:"Status",
        span3:"Amount",
        span4:"Time",
        type0:"Buy up",
        type1:"Buy down",
        type2:"Transfer in",
        type3:"Transfer out",
        type4:"Settlement",
        type5:"Handling fee",
        type6:"System leveling",
        type7:"System operation",
        state0:"Pending",
        state1:"Finish",
        state2:"Fail",
    },

    r:{
        r1:"Registration requires browsing and agreeing",
        r2:"user Agreement",
    },

    new_list:{
        h:"New Currency Quotes",
        span2:"Issue price",
        span3:"Issue date",
    },

    issue:{
        onOrderShow:"Purchase",
        issue_amount:"Issue number",
        use_issue_amount:"Subscription amount",
        close_day:"Closed days",
        label:"Purchase quantity",
        placeholder:"Please enter the order quantity",
        Cancel:"Cancel",
        Submit:"Submit",
        detail:"Details description",
    },
    issue_order:{
        col1:"Pair",
        col2:"Subscription price",
        col3:"Amount",
        col4:"Total Amount",
        col5:"Closed days",
        col6:"Creation time",
    },

    contract_shenqin:{
        text_notice1:'You have not submitted real-name authentication, please submit real-name authentication first',
        text_notice2:'Your real-name authentication failed, please re-submit real-name authentication',
        text_notice3:'Your real-name authentication is under review',
        tips:'Tips',
        confirmButtonText:'Confirm',
        cancelButtonText:'Cancel',
        // confirm_text:'Contract transactions require assets to reach more than 10,000 USDT, please submit an application',
        confirm_text:'Please submit a contract application, click confirm to complete the application, and once the application is successful, you can proceed with the transaction',
        dataVerify_text:'Your application for contract trading permission is being processed, please wait for it to be processed',
    },

    is_cancel_order:"Are you sure you want to cancel this order?",
    cancel_order:"Cancel",
    send_img:"Upload voucher",

    // 現貨新增市價限價
    youjia:"Trade at the current best price",
    shijia_chao:"Exceeded available balance",
    allBuyValueFnShijia_p:"Money",
    allSellValueFnShijia_p:"Amount",

    xinbiyure:{
        issue_price:'Issue price',
        issue_date:'Issue date',
        issue_amount:'Issue number',
        guimo:'Fundraising scale',
        use_issue_amount:'Cumulative purchase quota',
        close_day:'Freeze days',
        published_date:'Announcement date of the lottery',
        showToast1:"Exceed the available purchase limit",
        showToast1_rengou:"Exceed the remaining subscription amount",
        issue_plc:"Please enter the purchase amount",

        can_num_h:"Amount available for purchase",
        orderFormData_amount1:"Please enter the purchase amount",
        can_num_h1:"Remaining subscription quota",
        orderFormData_amount2:"Please enter the subscription amount",

        // 新增可用餘額usdt（現貨）
        can_num_h_u:"Available Balance",  
        showToast_u:"Exceeded available balance",

        span1:"Trading pair",
        span2:"Subscription amount",
        span3:"State",
        span4:"Lottery amount",
        span5:"Remaining subscription quota",
        span6:"Subscription time",
        span7:"Operate",
        buyFn:"Subscribe",
        state_arr1:"Subscribing",
        state_arr2:"Victores",
        state_arr3:"Finish",
        state_arr4:"Finish",
        amount_message:"Please enter the subscription amount",
    },

    recharge_manage:{
        title:'Management fee wallet'
    },

    money_error:{
        text1:'Cannot be empty',
        text2:'Only two decimal places can be retained',
    },
}