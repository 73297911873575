export default {
    b_buy:'Buy',
    b_sell:'Sell',

    c_buy:'Buy',
    c_sell:'Sell',

    n_detail:'รายละเอียด',
    n_more:'ประกาศ',

    currency_exchange_rate:'อัตราแลกเปลี่ยนสกุลเงิน',
    submit: 'ส่ง',
	amount: 'จำนวนเงิน',
	price: 'ราคา',
	available: 'ใช้ได้',
	total: 'รวม',
	no_more: 'ไม่มีอีกแล้ว',
	optional: 'ไม่จำเป็น',
	remark: 'หมายเหตุ',
	tips: 'เคล็ดลับที่อบอุ่น',
	copied: 'คัดลอกแล้ว',
	petf: 'โปรดป้อนฟิลด์นี้',
	MAX: 'สูงสุด',
    buyUp:"ซื้อขึ้น",
    buyDown:"ซื้อและลง",
    look:"ดู",
    id_image_a:"กรุณาอัพโหลดรูปถ่ายหน้าบัตรประชาชน",
    id_image_b:"กรุณาอัพโหลดรูปถ่ายหลังบัตรประชาชน",
    rfields:"รายการที่จำเป็น",
    
    imageLimit:"ขนาดไฟล์ภาพต้องไม่เกิน 5 เมตร",

    search_text:"กรุณาใส่สกุลเงิน (เช่น BTC)",
    registerS:'ลงทะเบียนสำเร็จ',
    shouyi_t:'อัตราผลตอบแทนรวม',

    // 新加
    required_address0:'ที่อยู่ลงทะเบียน',
    required_address:'กรุณากรอกที่อยู่ที่ลงทะเบียน',
    required_swift:'กรุณากรอก swift code',

    phoneVerified:'รายละเอียดการติดต่อ',
    phoneVerifiederr:'กรุณากรอกรายละเอียดการติดต่อ',

    //公賬驗證
    corporate_account_power:{
        h_1:"การยืนยันบัญชี USD to Public",
        p_1:"การฝากเงินดอลลาร์สหรัฐต้องผ่านการตรวจสอบบัญชีสาธารณะจึงจะสามารถดำเนินการได้",
        btn:"การยืนยันตัวตน",
        h_2:"เคล็ดลับ",
        p_2:"คุณได้ทำการยืนยันบัญชี USD ต่อบัญชีสาธารณะแล้ว โปรดรอการตรวจสอบผ่าน",
    },

    //交易方向
    onJump1:"การซื้อขายสปอต",
    onJump2:"การซื้อขายสัญญา",

    news_center:"ศูนย์ข่าว",

    middle_window:{
        Position:"สถานที่ตั้ง",
        Price:"ราคา",
        Amount:"ปริมาณ",
    },
    Confirm_order:{
        title:"ยืนยันการสั่งซื้อ",
        h:"ราคาปัจจุบัน",
        Due_time:"เวลาตัด (วินาที)",
        Profit:"ผลประโยชน์",
        label1:"จำนวนเงินลงทุน (ลงทุนครั้งเดียวจำนวนไม่น้อย 10 ครั้ง)",
        plc1:"ใส่จำนวนเงินลงทุน",
        Expected_profits:"กำไรที่คาดหวัง",
        Guaranteed_amount:"จำนวนเงินเอาประกันภัย",
    },
    navbar: {
        home: 'หน้าหลัก',
        subscribe: 'สมัครสมาชิก',
        fund: 'กองทุนบล็อก',
        help: 'ความช่วยเหลือ',
        language: 'ภาษา',
        sign_in: 'เข้าสู่ระบบ',
        my_assets: 'ทรัพย์สินของฉัน',
        profile: 'ข้อมูลส่วนตัว',
        settings: 'การตั้งค่า',
        logout: 'ออกจาก Login',

        new1:'ตัวอย่างเหรียญใหม่',
        new2:'สภาวะตลาด',
        new3:'ในสต็อก',
        new4:'สัญญา',
        new5:'สกุลเงินฝรั่งเศส',
        // new_new:"新幣行情",
        new_new:"สกุลเงินที่ใช้งานอยู่",
    },
    footer: {
        about_us: 'กวน Yu เรา',
        help_center: 'ศูนย์ช่วยเหลือ',
        fees: 'ค่าธรรมเนียมการจัดการ',
        user_agreement: 'เงื่อนไขการให้บริการ',
        contact_us: 'ติดต่อเรา',
        copyright: 'ลิขสิทธิ์ทั้งหมด',
        qualification: 'ข้อมูลคุณสมบัติ',
    },
    base: {
        back: 'กลับ',
        buy: 'ซื้อ',
        sell: 'ขาย',
        no_more: 'รายการเปล่า',
        loading: 'กำลังโหลด',
        sending: 'กำลังส่ง',
        pending: 'ในการประมวลผล',
        success: 'ความสำเร็จ',
        fail: 'ล้มเหลว',
        warning: 'เคล็ดลับ',
        danger: 'คำเตือน',
        error: 'ข้อผิดพลาด',
        email: 'กล่องจดหมาย',
        password: 'รหัสผ่าน',
        old_pw: 'รหัสผ่านเก่า',
        new_pw: 'รหัสผ่านใหม่',
        re_pw: 'เปลี่ยนรหัสผ่าน',
        pay_password: 'รหัสผ่านการเทรด',
        submit: 'ส่ง',
        update: 'อัพเดท',
        new: 'เพิ่มใหม่',
        remark: 'หมายเหตุ',
        voucher: 'ข้อมูลประจำตัว',
        amount: 'จำนวนเงิน',
        enter_usdt_quantily: 'กรุณาใส่จำนวน USDT',
        enter_trade_password: 'กรุณากรอกรหัสผ่าน',
        login_first: 'กรุณาเข้าสู่ระบบก่อน',
        state: 'สถานะ',
        profit: 'ผลประโยชน์',
        coin: 'เหรียญ',
        coin_profit: 'กำไรเหรียญ',
        refresh: 'รีเฟรช',
        total: 'รวม',
        deal: 'การดำเนินงาน'
    },
    home: {
        // banner: [
        //     require('@/assets/index/banner/1-1.jpg'),
        //     require('@/assets/index/banner/2-1.jpg'),
        //     require('@/assets/index/banner/3-1.jpg')
        // ],
        title1: 'Happycoinage',
        title2: 'การซื้อขายที่รวดเร็วสินทรัพย์ที่ปลอดภัย',
        opened: 'เปิดแล้ว',
        more: 'มากกว่า',
        trade: 'การซื้อขาย',
        pair: 'คู่เทรด',
        price: 'ราคาล่าสุด',
        change: 'ความผันผวน',
        high: 'สูงสุด',
        low: 'ต่ำสุด',
        finished: 'ปิดดีล',
        deal: 'การดำเนินงาน',
        // service_title: '立即開始您的加密貨幣之旅',
        service_title: 'จุดเด่น 4 ประการ',
        service1: {
            title: 'ระบบที่เชื่อถือได้',
            des1: 'รับประกันความปลอดภัยของการทำธุรกรรมมีเสถียรภาพและมีประสิทธิภาพ',
            des2: 'ทีมระดับหัวกะทิที่มีความสามารถชั้นนำของโลก'
        },
        service2: {
            title: 'ความปลอดภัยของเงินทุน',
            des1: 'การเข้ารหัสข้อมูล HTTPS, การตรวจสอบสิทธิ์',
            des2: 'การจัดเก็บกระเป๋าสตางค์เย็นสถาปัตยกรรมระดับการเงินการป้องกันความปลอดภัยหลายระดับ'
        },
        service3: {
            title: 'ประสบการณ์ที่ดีที่สุด',
            des1: 'ถอนเงินด่วน, หลายภาษา',
            des2: 'กลไกการซื้อขายที่มีประสิทธิภาพสูงประสบการณ์การซื้อขายที่รวดเร็ว'
        },
        service4: {
            title: 'บริการระดับมืออาชีพ',
            des1: 'ทีมบริการระดับมืออาชีพระหว่างประเทศ',
            des2: 'ตอบสนองปัญหาของผู้ใช้อย่างทันท่วงที'
        },
        guide_title:'คู่มือสำหรับมือใหม่',
    },
    fund: {
        banner1: {
            title1: 'สิทธิประโยชน์การส่งมอบสัญญาถาวรของมาตรฐาน USDT',
            title2: 'แมริออทลี่รอคุณสูบ'
        },
        banner2: {
            title1: 'กองทุนบล็อกเชน',
            title2: 'แนะนำเพื่อนที่ประสบความสำเร็จจะได้รับ USDT สูง'
        },
        banner3: {
            title1: 'XMR、IOTA、AR、XRT、',
            title2: 'สัญญาต่อเนื่องมาตรฐาน USDT เปิดตัวอย่างเป็นทางการ'
        },
        newest: 'อัพนิว',
        my_participation: 'ฉันมีส่วนร่วม',
        notify_message: 'ขอแสดงความยินดีกับความสำเร็จของ Grab Buy',
        incentive_mechanism: 'ระบบการให้รางวัล',
        locked_staking: 'ล็อคไซโลได้รับเหรียญ',
        ongoing: 'กำลังดำเนินการ',
        sold_out: 'จบแล้ว',
        countdown_starts:'เริ่มใน 5 นาที',
        profit: 'ผลประโยชน์',
        days: 'วัน',
        user_limit: 'การจำกัดจำนวนผู้ใช้ที่เข้าร่วม',
        applicants_user: 'จำนวนผู้สมัคร',
        buy_range: 'ช่วงจำนวนเงินที่เข้าร่วม',
        read_more: 'ดูรายละเอียด',
        purchase: 'การมีส่วนร่วม',
        available: 'ใช้ได้',
        success_title: 'ส่งเรียบร้อยแล้ว',
        success_msg:'ส่งเรียบร้อยแล้ว นับถอยหลังสิ้นสุดการประกาศผล Grab Buy โปรดตรวจสอบการเข้าร่วมของฉัน',
        lock_up_the_rules: 'กฎล็อคไซโล',
        lock_up_the_rules_1: 'ผลการจับสลากประกาศหลังการนับถอยหลังสิ้นสุดในแต่ละเกม',
        lock_up_the_rules_2: 'ปลดล็อคโดยอัตโนมัติหลังจากเวลาล็อคหมดอายุ',
        lock_up_the_rules_3: 'คำอธิบายรายได้ TAOGE: รายได้รวม USDT หารด้วยราคา TAOGE แบบเรียลไทม์และแปลงเป็นปริมาณ TAOGE ซึ่งเป็นรายได้รวมของ TAOGE',
        lock_up_the_rules_4: 'รายได้ USDT ที่ได้รับหลังจากล็อคจะถูกโอนไปยังบัญชีส่วนบุคคลและรายได้ของ TAOGE จะถูกระงับจนถึงวันที่ 30 สิงหาคม 2021 หลังจากนั้นจะสามารถยกเลิกการระงับการซื้อขายได้ฟรี',
        project_introduction: 'แนะนำโครงการ',
        project_introduction_content: 'กองทุนเพื่อ TAOGE Block Fund สามารถมีผลตอบแทนสูงนี้ได้หรือไม่ เรามีกำไรเชิงปริมาณทางการเงินที่แข็งแกร่งซึ่งรวมถึงการเก็งกำไรป้องกันความเสี่ยงความถี่สูงข้ามตลาดต่างประเทศของ USDT ซึ่งได้รับการจัดการโดยตรงโดยสถาบัน Cloud Cryptal Power และ CFO อดีต JPMorgan Chase (Marianne Lake) ทีมผู้บริหารหลักมีประสบการณ์การต่อสู้จริงในภาคการเงินมานานกว่า 15 ปี มีความเข้าใจเป็นอย่างดีเกี่ยวกับความเสี่ยงและแนวทางในการตอบสนองต่อการเก็งกำไรป้องกันความเสี่ยง และได้พัฒนากลยุทธ์การป้องกันความเสี่ยงเชิงปริมาณที่ค่อนข้างเป็นผู้ใหญ่',
        lock_period: 'รอบโพซิชั่นผิด',
        lock_start_time: 'เวลาเริ่มต้นของล็อคไซโล',
        lock_end_time: 'เวลาสิ้นสุดล็อคไซโล',
        state: {
            checking: 'ในการตรวจสอบ',
            locking: 'ในไซโลล็อค',
            finished: 'เสร็จสิ้นการปล่อยตัว',
            fail: 'ล้มเหลว'
        }
    },
    subscribe: {
        title1: 'สมัครสมาชิก New Coin',
        title2: 'เรามุ่งมั่นที่จะช่วยให้โครงการ blockchain ระดมทุนหาผู้สนับสนุนและนำเสนอแนวคิดที่ดีที่แปลกใหม่และน่าสนใจ',
        new_currency: 'เหรียญใหม่',
        my_participation: 'สมัครสมาชิกของฉัน',
        project_details: 'รายละเอียดโครงการ',
        subscription_ratio: 'สัดส่วนการสมัคร',
        issue_quota: 'โควต้าการออก',
        participating: 'มีส่วนร่วมแล้ว',
        lock_period: 'วงจรล็อคไซโล',
        months: 'เดือน',
        thaw_interval: 'ช่วงเวลาการละลายน้ำแข็ง',
        hours: 'ชั่วโมง',
        available: 'ใช้ได้',
        purchase_limit: 'ข้อจำกัดในการเข้าร่วม',
        total: 'รวม',
        fee: 'ค่าธรรมเนียมการจัดการ',
        get_involved_now: 'เข้าร่วมตอนนี้',
        end_date: 'วันที่สิ้นสุด',
        project_des: 'แนะนำโครงการ',
        white_paper: 'สมุดปกขาว'
    },
    login: {
        title: 'เข้าสู่ระบบสมาชิก',
        dont_have_an_account:'คุณยังไม่มีหมายเลขบัญชีสมาชิก?',
        to_register: 'ไปที่ลงทะเบียน',
        email: 'ที่อยู่อีเมล',
        password: 'รหัสผ่านเข้าสู่ระบบ',
        captcha: 'กราฟิก CAPTCHA',
        click_me_show: 'คลิกเพื่อแสดง',
        sign_in_now: 'เข้าสู่ระบบตอนนี้',
        forget_password: 'ลืมรหัสผ่าน?',

        emailPhone:'กล่องจดหมายหรือหมายเลขโทรศัพท์มือถือ',
        min6:'(ขั้นต่ำ 6 ท่าน)',
        rules1:'โปรดป้อนกล่องจดหมายหรือหมายเลขโทรศัพท์มือถือ',
        rules2:'กรุณากรอกรหัสผ่านอย่างน้อย 6 หลัก',
        rules3:'กรุณากรอกรหัสผ่านซ้ำอย่างน้อย 6 หลัก',
        rules3_2:'ป้อนรหัสผ่านไม่สอดคล้องกันสองครั้ง',
        rules4:'กรุณากรอกรหัสเชิญ',
        rules5:'การลงทะเบียนจะต้องเรียกดูและยอมรับข้อตกลงผู้ใช้',
        captcha_plc:'กรุณากรอกรหัสยืนยันอย่างน้อย 4 หลัก',
    },
    register: {
        title: 'ลงทะเบียนสมาชิก',
        phone: 'หมายเลขโทรศัพท์',
        re_password: 'ทำซ้ำรหัสผ่าน',
        invitation_code: 'รหัสเชิญ',
        email_captcha: 'รหัสยืนยันกล่องจดหมาย',
        send_to_email: 'ส่งไปยังกล่องจดหมาย',
        already_have_an_account: 'คุณมีหมายเลขบัญชีสมาชิกอยู่แล้ว ?',
        sign_in: 'เข้าสู่ระบบ'
    },
    forget: {
        title: 'รีเซ็ตรหัสผ่าน',
        retrieve_now: 'รีเซ็ตทันที'
    },
    account: {
        title: 'บัญชี',
        recharge: 'เติมเงิน',
        profile: 'ข้อมูลส่วนตัว',
        my_assets: 'ทรัพย์สินของฉัน',
        withdraw: 'ถอนเงินสด',
        transactions: 'การซื้อขายสปอต',
        micro_contract: 'การซื้อขายสัญญาไมโคร',
        contract:'การซื้อขายสัญญา',
        inviting_and_awards: 'คำเชิญ & ผู้ช่วยส่งกลับ',
        settings: 'การตั้งค่า',
        list_of_assets: 'รายการทรัพย์สิน',
        available: 'ใช้ได้',
        frozen: 'แช่แข็ง',
        available_log: 'บันทึกที่มีอยู่',
        event: 'กิจกรรม',
        time: 'เวลา',
        event_type: {
            recharge: 'เติมเงิน',
            take: 'หัก',
            withdraw: 'ถอนเงินสด',
            withdraw_fail: 'การถอนเงินล้มเหลว',
            trade_buy: 'ซื้อ Spot',
            trade_sell: 'ขาย Spot',
            trade_success: 'ความสำเร็จในการเทรด',
            trade_cancel: 'การถอนเงินการซื้อขาย',
            subscribe: 'สมัครสมาชิก',
            contract_buy: 'การสั่งซื้อ Microcontract',
            contract_back: 'Microcontract กลับ',
            contract_win: 'Microcontract ทำกำไร',
            frozen: 'แช่แข็ง',
            thaw: 'ละลายน้ำแข็ง',
            fund_buy: 'การซื้อกองทุนบล็อก',
            fund_buy_fail: 'การซื้อกองทุนบล็อกล้มเหลว',
            fund_back: 'Block Fund เงินต้นคืน',
            fund_income: 'กองทุน Block Fund Income',
            cfl1ua: 'รางวัล Straight Push ถาวร',
            cfl1u: 'โบนัสกดตรง',
            cfl2u: 'รางวัลระดับ 2',
            cfl3u: 'รางวัลระดับ 3',
            exchange: 'แฟลชไดรฟ์',

            // 新增
            new25:'โอนเข้าบัญชีสัญญา',
            new26:'โอนเข้าบัญชีสัญญา',
            new27:'การเติมเงินสกุลฝรั่งเศส',
            new28:'การถอนเงินสกุลฝรั่งเศส',
            new29:'สมัครสมาชิก New Coin',
            new30:'เงินรางวัล',
            new31:'ค่าบริการ',
        },
        email: 'ที่อยู่อีเมล',
        verify: 'การรับรอง',
        verify_state: {
            unsubmitted: 'ยังไม่ได้ส่ง',
            fail: 'ล้มเหลว',
            checking: 'เพื่อตรวจสอบ',
            success: 'เสร็จสิ้น'
        },
        verify_page:{
            rules1:'กรุณาใส่ชื่อบัตรประชาชน',
            rules2:'กรุณากรอกหมายเลขบัตรประชาชน',
        },
        addressTitle:'ที่อยู่กระเป๋าสตางค์เย็น',
    },
    wallet: {
		tb: 'สินทรัพย์รวม',
		recharge: 'การชาร์จเหรียญ',
		withdraw: 'หยิบเหรียญ',
		exchange: 'แฟลชไดรฟ์',
		in_order: 'แช่แข็ง',
		value: 'โฮป',
		pea: 'กรุณาใส่จำนวนเงิน',
		petp: 'กรุณากรอกรหัสผ่านกองทุน',
		log_type: [
			'เติมเงิน',
			'หัก',
			'ถอนเงินสด',
			'การถอนเงินล้มเหลว',
			'ซื้อขายซื้อ',
			'ซื้อขายขาย',
			'ความสำเร็จในการเทรด',
			'การถอนเงินการซื้อขาย',
			'ซื้อเหรียญ',
			'สัญญาซื้อขาย ซื้อ',
			'การเสมอการซื้อขายสัญญา',
			'การซื้อขายสัญญาชนะ',
			'การแช่แข็งระบบ',
			'ระบบละลายน้ำแข็ง',
			'การซื้อกองทุนโทเค็น',
			'การซื้อกองทุนโทเค็นล้มเหลว',
			'กองทุนโทเค็นปล่อยเงินต้น',
			'รายได้จากกองทุนโทเค็น',
			'บริการผลักกลับแบบถาวร',
			'บริการผลักกลับโดยตรง',
			'ค่าคอมมิชชั่นคืนระดับ 2',
			'ค่าคอมมิชชั่นคืนระดับ 3',
			'แฟลชไดรฟ์',
			'แฟลชไดรฟ์',
			'แฟลชไดรฟ์'
		]
	},
    recharge: {
		title: 'การชาร์จเหรียญ',
		cc: 'คลิกเพื่อคัดลอก',
		uv: 'อัปโหลดข้อมูลประจำตัว',
		puv: 'กรุณาอัพโหลดข้อมูลประจำตัว',
		nor: 'ไม่เปิดให้เติมเงิน',
		history: {
			title: 'บันทึกการเติมเงิน',
			checking: 'เพื่อตรวจสอบ',
			success: 'ประสบความสำเร็จในการเข้าบัญชี',
			fail: 'ล้มเหลว'
		},
		tips: {
			USDT: [
				'โปรดเลือกเครือข่าย ERC20/OMNI/TRC20 และถอนเงิน USDT ไปยังที่อยู่ข้างต้นมิฉะนั้นสินทรัพย์จะไม่สามารถกู้คืนได้ หลังจากเติมเงินไปยังที่อยู่ข้างต้นจำเป็นต้องยืนยันโหนดเครือข่ายทั้งหมด 12 ครั้งยืนยันบัญชีหลังจากการยืนยันเครือข่าย 12 ครั้งยืนยันการถอนเงินหลังจากการยืนยันเครือข่าย',
				'ยอดเติมเงินขั้นต่ำ 200USDT ซึ่งน้อยกว่ายอดเติมเงินขั้นต่ำจะไม่ได้รับเครดิตและไม่สามารถคืนเงินได้ ที่อยู่เติมเงินของคุณจะไม่มีการเปลี่ยนแปลงบ่อยนัก หากมีการเปลี่ยนแปลงคุณสามารถเติมเงินได้ เราจะแจ้งให้คุณทราบผ่านทางประกาศของเว็บไซต์หรือทางไปรษณีย์',
				'โปรดตรวจสอบให้แน่ใจว่าคอมพิวเตอร์และเบราว์เซอร์ของคุณปลอดภัยและป้องกันไม่ให้ข้อมูลถูกเปลี่ยนแปลงหรือรั่วไหล'			
			],
			BTC: [
				'โปรดเลือกเครือข่าย ERC20/OMNI/TRC20 และถอนเงิน BTC ไปยังที่อยู่ข้างต้นมิฉะนั้นสินทรัพย์จะไม่สามารถกู้คืนได้ หลังจากเติมเงินไปยังที่อยู่ข้างต้นจำเป็นต้องยืนยันโหนดเครือข่ายทั้งหมด 12 ครั้งยืนยันบัญชีหลังจากการยืนยันเครือข่าย 12 ครั้งยืนยันการถอนเงินหลังจากการยืนยันเครือข่าย',
				'ยอดเติมเงินขั้นต่ำ 200USDT ซึ่งน้อยกว่ายอดเติมเงินขั้นต่ำจะไม่ได้รับเครดิตและไม่สามารถคืนเงินได้ ที่อยู่เติมเงินของคุณจะไม่มีการเปลี่ยนแปลงบ่อยนัก หากมีการเปลี่ยนแปลงคุณสามารถเติมเงินได้ เราจะแจ้งให้คุณทราบผ่านทางประกาศของเว็บไซต์หรือทางไปรษณีย์',
				'โปรดตรวจสอบให้แน่ใจว่าคอมพิวเตอร์และเบราว์เซอร์ของคุณปลอดภัยและป้องกันไม่ให้ข้อมูลถูกเปลี่ยนแปลงหรือรั่วไหล'			
			],
			ETH: [
				'โปรดเลือกเครือข่าย ERC20/OMNI/TRC20 และถอนเงิน ETH ไปยังที่อยู่ข้างต้นมิฉะนั้นสินทรัพย์จะไม่สามารถกู้คืนได้ หลังจากเติมเงินไปยังที่อยู่ข้างต้นจำเป็นต้องยืนยันโหนดเครือข่ายทั้งหมด 12 ครั้งยืนยันบัญชีหลังจากการยืนยันเครือข่าย 12 ครั้งยืนยันการถอนเงินหลังจากการยืนยันเครือข่าย',
				'ยอดเติมเงินขั้นต่ำ 200USDT ซึ่งน้อยกว่ายอดเติมเงินขั้นต่ำจะไม่ได้รับเครดิตและไม่สามารถคืนเงินได้ ที่อยู่เติมเงินของคุณจะไม่มีการเปลี่ยนแปลงบ่อยนัก หากมีการเปลี่ยนแปลงคุณสามารถเติมเงินได้ เราจะแจ้งให้คุณทราบผ่านทางประกาศของเว็บไซต์หรือทางไปรษณีย์',
				'โปรดตรวจสอบให้แน่ใจว่าคอมพิวเตอร์และเบราว์เซอร์ของคุณปลอดภัยและป้องกันไม่ให้ข้อมูลถูกเปลี่ยนแปลงหรือรั่วไหล'			
			],
			LTC: []
		}
	},
    withdraw: {
		title: 'หยิบเหรียญ',
		now: 'ไม่เปิดให้หยิบเหรียญ',
        swa: 'เลือกที่อยู่กระเป๋าสตางค์',
        awa: 'เพิ่มที่อยู่กระเป๋าสตางค์',
        nwa: 'ที่อยู่กระเป๋าสตางค์ใหม่',
		history: {
			title: 'บันทึกการหยิบเหรียญ',
			checking: 'เพื่อตรวจสอบ',
			success: 'ความสำเร็จ',
			fail: 'ล้มเหลว',
			cancel: 'การยกเลิก'
		}
	},
    exchange: {
		title: 'แฟลชไดรฟ์',
		history: {
			title: 'บันทึกแฟลชไดรฟ์'
		}
	},
    share: {
		title: 'แบ่งปันเพื่อน',
		cic: 'คัดลอกรหัสเชิญ',
		t: 'กองทุน Memory Blockchain ส่งเสริมกลไกรางวัลการแบ่งปัน',
		t1: 'ตัวแทนส่งเสริมการขายโดยตรง',
		t1_1: '1. โปรโมชั่นโดยตรงต่ำกว่า 2 ท่าน รับโบนัส 2% USDT สำหรับสตรีมเทรด',
		t1_2: '2. โปรโมชั่นโดยตรงมากกว่า 3 ท่าน (รวม 3 ท่าน) รับโบนัส 4% USDT สำหรับสตรีมเทรด',
		t1_3: '3. โปรโมชั่นโดยตรงมากกว่า 5 ท่าน (รวม 5 ท่าน) รับรางวัล 6% USDT สำหรับสตรีมเทรด',
		t1_4: '4. โปรโมชั่นโดยตรงมากกว่า 10 ท่าน (รวม 10 ท่าน) เพื่อรับโบนัส 8% USDT สำหรับสตรีมการซื้อขาย',
		t2: 'ตัวแทนระดับ 2',
		t2_1: '1. ตัวแทนระดับ 2 ที่มีจำนวนต่ำกว่า 4 คน จะได้รับโบนัส 1% USDT สำหรับสตรีมมิ่งเทรด',
		t2_2: '2. ตัวแทนระดับ 2 ถึง 4 คน (รวม 4 คน) เพื่อรับรางวัล 2% USDT สำหรับสตรีมการซื้อขาย',
		t2_3: '3. ตัวแทนระดับ 2 ถึง 6 คน (รวม 6 คน) เพื่อรับรางวัล 4% USDT สำหรับสตรีมเทรด',
		t3: 'ตัวแทนระดับ 3',
		t3_1: '1. ตัวแทนระดับ 3 ถึง 1 คน พร้อมรับรางวัล 3% USDT สำหรับสตรีมเทรด',
		t3_2: '2. ตัวแทนระดับ 3 ถึง 4 คน (รวม 4 คน) เพื่อรับรางวัล 6% USDT สำหรับสตรีมเทรด',
		t3_3: '3. ตัวแทนระดับ 3 ถึง 6 คน (รวม 6 คน) เพื่อรับรางวัล 8% USDT สำหรับสตรีมเทรด',
		team: {
			title: 'เชิญชวนให้ดื่มกลับไปช่วยแม่บ้าน',
			ct1: 'จำนวนผู้เชิญทั้งหมด',
			ct2: 'จำนวนผู้เชิญวันนี้',
			ct3: 'รายได้วันนี้',
			ct4: 'รายได้รวม',
			ct5: 'ยอดรวม',
			level_1: 'ชั้น 1', 
			level_2: 'ระดับ 2', 
			level_3: 'ระดับ 3',
		}
	},
    user: {
		email: 'ที่อยู่อีเมล',
		email_captcha: 'รหัสยืนยันกล่องจดหมาย',
		captcha: 'รหัสยืนยันรูปภาพ',
		pwd: 'รหัสผ่าน',
		confirm_pwd: 'ยืนยันรหัสผ่าน',
		trade_pwd: 'รหัสผ่านกองทุน',
		confirm_trade_pwd: 'ยืนยันรหัสผ่านกองทุน',
		wa: 'ที่อยู่กระเป๋าสตางค์',
		peea: 'โปรดป้อนที่อยู่อีเมล',
		pewa: 'กรุณาใส่ที่อยู่กระเป๋าสตางค์',
		pec: 'กรุณาใส่รหัสยืนยันรูปภาพ',
		peec: 'โปรดป้อนรหัสยืนยันกล่องจดหมาย',
		cfii: 'รูปแบบ CAPTCHA กราฟิกเป็นองค์ประกอบของตัวอักษรและตัวเลข',
		ecfii: 'รหัสยืนยันกล่องจดหมายประกอบด้วยตัวเลข',
		eafii: 'รูปแบบที่อยู่อีเมลไม่ถูกต้อง',
		pep: 'กรุณากรอกรหัสผ่าน',
		pecp: 'กรุณายืนยันรหัสผ่าน',
		p_diff: 'รหัสผ่านที่ป้อนทั้งสองครั้งไม่สอดคล้องกัน',
		pfii: 'รหัสผ่านประกอบด้วยตัวอักษรและตัวเลขและสัญลักษณ์',
		uff: 'การอัพโหลดไฟล์ล้มเหลว'
	},
    contact_about:{
        label1:'การสั่งซื้อตามสัญญา',
        top_text_h1:'มูลค่าสุทธิ',
        top_text_h2:'มาร์จิ้น',
        top_text_h3:'กำไรจากการเปิดสถานะ',
        available:'ยอดคงเหลือที่มีอยู่',
        fundswap:'แลกเปลี่ยนเงิน',
        form:'เริ่มต้นจาก',
        to:'ถึง',
        pair:'ไปยัง Coin',
        num:'ปริมาณ',
        num_plc:'กรุณาใส่ปริมาณ',
        all:'ทั้งหมด',
        cancel:'การยกเลิก',
        confirm:'ระบุ',
        margin:'มาร์จิ้น',
        margin1:'มาร์จิ้นที่เหลือ',
        lever:'เลเวอเรจ',
        please_choose:'กรุณาเลือก',
        contract_order:'คำสั่งซื้อตามสัญญา',
        position_order:'คำสั่งซื้อขาย',
        current_commission:'ปัจจุบัน Commission',
        switchBtn:'ดูเฉพาะพันธุ์ปัจจุบัน',
        liquidation:'การปิด',
        table1:{
            pair:'ไปยัง Coin',
            type:'ประเภท',
            income:'ผลประโยชน์',
            rate_return:'อัตราผลตอบแทน',
            deal:'การซื้อขาย',

            span_h1:'ราคาค่าคอมมิชชั่น',
            span_h2:'ราคาเปิด',
            span_h3:'มาร์จิ้น',
            span_h4:'ราคาล่าสุด',
            span_h5:'เวลามอบอำนาจ',
            span_h6:'เวลาเปิดสถานะ',
            span_h7:'เลเวอเรจ',

            span_h8:'ราคาปิด',
            span_h9:'เวลาปิดสถานะ',

            service_charge:'ค่าธรรมเนียมการจัดการ',
        },
        table2:{
            type:'ประเภท',
            price:'ราคา',
            amount:'ปริมาณ',
            time:'เวลา',
            deal:'การซื้อขาย',

            service_charge:'ค่าบริการ',
        },
        recharge_text1:"กระเป๋าสตางค์ USDT Spot",
        recharge_text2:"กระเป๋าสตางค์สัญญา USDT",
        error1:'กรุณาใส่จำนวนและต้องไม่เกินยอดคงเหลือ',
    },
    recharge_new:{
        verified_tip:'กรุณาผ่านการรับรองก่อนจึงจะสามารถใช้ฟังก์ชั่นเติมเงินได้',
        table_item1:'หมายเลขการสั่งซื้อ',
        table_item2:'สถานะ',
        table_item3:'ประเภท',
        table_item4:'จำนวนเงิน',
        table_item5:'คูปอง',
        table_item6:'เวลา',

        table_item7:'ที่อยู่',

        table_state1:'ในการส่ง',
        table_state2:'ความสำเร็จ',
        table_state3:'ล้มเหลว',

        amount_mes:'ตัวเลขบริสุทธิ์และเก็บทศนิยมได้สูงสุด 2 หลัก',
        amount_mes2:'ตัวเลขบริสุทธิ์และเก็บทศนิยมได้สูงสุด 8 หลัก',
        message_img:'กรุณาอัพโหลดรูปคูปอง',
        message_password:'รหัสผ่านการเทรดต้องไม่ว่างเปล่า',
        message_bal:'การถอนเงินต้องไม่เกินยอดคงเหลือ',

        table_item_new1:'โอนไปยังสกุลเงิน',
        table_item_new2:'จำนวนโอน',
        table_item_new3:'ไปยังสกุลเงินบัญชี',
        table_item_new4:'จำนวนบัญชี',
    },
    address:{
        title:'ที่อยู่กระเป๋าสตางค์เย็น',
        wallet_address:'โปรดป้อนที่อยู่กระเป๋าสตางค์เย็น',
        wallet_list_title:'รายการกระเป๋าสตางค์เย็น',
        wallet_type:'ประเภท',
        wallet_address_title:'ที่อยู่กระเป๋าสตางค์เย็น',
        wallet_opea:'การดำเนินงาน',
        wallet_cancel:'การยกเลิก',
        wallet_err:'ที่อยู่กระเป๋าสตางค์ต้องไม่ว่างเปล่า',
        wallet_tip:'จำกัด 10 กระเป๋าสตางค์เย็น',
    },
    //現貨訂單列表
    transactions:{
        span1:'ประเภท',
        span2:'ไปยัง Coin / Coin',
        span3:'ราคาค่าคอมมิชชั่น',
        span4:'ปริมาณ',
        span5:'ราคาปิด',
        span6:'ราคารวม',
        span7:'เวลา',
        span8:'สถานะ',

        cancelBtn:'การยกเลิกคำสั่งซื้อ',

        state_new1:'สั่งซื้อสินค้า',
        state_new2:'ผู้ใช้ยกเลิก',
        state_new3:'ปิดการสั่งซื้อ',
        state_new4:'ระบบยกเลิก',
    },
    micro_contract:{
        span1:'ประเภท',
        span2:'ไปยัง Coin / Coin',
        span3:'ปริมาณ',
        span4:'รอบ / อัตราผลตอบแทน',
        span5:'ผลประโยชน์',
        span6:'ราคาที่ชำระราคา',
        span7:'เวลาชำระราคา',
        span8:'สถานะ',

        state1:'เพื่อดำเนินการ',
        state2:'ความสำเร็จ',
        state4:'ล้มเหลว',
    },

    // 現貨詳情
    trading_index:{
        el_title:'สั่งซื้อสินค้า',
        el_title1:'คำสั่งมอบหมาย',
        el_switch1:'ดูเฉพาะพันธุ์ปัจจุบัน',
        message1:'ราคารวมที่ซื้อต้องไม่เกินยอดคงเหลือ',
        message2:'ยอดซื้อต้องไม่เกินยอดคงเหลือ',

        // 新加市價限價
        tab_1:'การกำหนดราคาตลาด',
        tab_2:'จำกัด ค่าคอมมิชชั่น',
    },

    user_contract:{
        placeholder1:'กรุณาเลือก',
        span1:'เลิกทำ',
        span2:'ผู้ใช้ยกเลิก',
        span4:'ตำแหน่งปิด',
        span5:'ระบบยกเลิก',
        rentext1:'สกุลเงินทั้งหมด',
        rentext2:'ประเภททั้งหมด',
        rentext3:'การสั่งซื้อ',
        rentext4:'คำสั่งซื้อขาย',
        rentext5:'สถานะทั้งหมด',
        rentext6:'สั่งซื้อสินค้า',
        rentext7:'ผู้ใช้ยกเลิก',
        rentext8:'ปิดดีล',
        rentext9:'การปิด',
        rentext10:'ระบบยกเลิก',
    },

    //個人設置導航
    settings:{
        nav1:'การรับรอง KYC',
        nav2:'รหัสผ่านเข้าสู่ระบบ',
        nav3:'รหัสผ่านการเทรด',
        nav4:'บันทึกการเข้าสู่ระบบ',
        ipAddress:"ที่อยู่ IP",
        Date_time:"เวลา",
    },

    fiat_currency:{
        message:{
            tips:'เคล็ดลับ',
            p_userToken:'กรุณาเข้าสู่ระบบก่อน',
            p_userVerified:'โปรดผ่านการรับรองชื่อจริงก่อน',
            confirm:"ยืนยัน",
            cancel:"การยกเลิก",
        },
        onTab1:"ดอลลาร์ไต้หวัน ไปยัง INR",
        onTab2:"USDT ขายอย่างรวดเร็ว",
        onTab3:"เงินดอลลาร์สหรัฐ",
        onTab4:"การชำระเงินด้วยเหรียญ fiat",
        span1:"ดีลเลอร์",
        span2:"ปริมาณ",
        span3:"วงเงิน",
        span4:"ราคาต่อหน่วย",
        span5:"วิธีการชำระเงิน",
        span6:"การซื้อขาย",
        span7:"ค่าธรรมเนียมการจัดการ",
        bank:"บัตรธนาคาร",
        open:"ซื้อ USDT",

        fait_pay:{
            inp1:'จำนวนเงินที่เติมเงิน（USDT）',
            plc1:'กรุณาใส่จำนวนเงินที่เติมเงิน',
            inp2:'จำนวนเงินที่จ่าย',
            plc2:'คำนวณยอดเงินโดยอัตโนมัติ',
        },

        recycle:{
            inp1:"ขายทั้งหมด ปริมาณ",
            plc1:"ขายทั้งหมด ปริมาณ",
            inp2:"สกุลเงิน",
            plc2:"กรุณาเลือกสกุลเงิน",
            inp3:"ยอดรวมของการขาย",
            plc3:"ยอดรวมของการขาย",
            inp4:"บัตรธนาคาร",
            plc4:"กรุณาเลือกบัตรธนาคาร",
            submitForm:"ส่ง",
            recycling_tips_title:"ข้อควรระวังเป็นพิเศษ",
            recycling_tip1:"โปรดใช้แพลตฟอร์มเพื่อรับรองบัตรธนาคารที่มีชื่อจริงและชื่อผู้รับไม่ตรงกับชื่อลงทะเบียนของแพลตฟอร์มไม่สามารถรีไซเคิลได้",
            recycling_tip2:"ช่วงเวลาการสั่งซื้อสูงสุดเนื่องจากการประมวลผลคำสั่งซื้อจำนวนมากอาจทำให้เกิดการคืนเงินเป็นเวลานานโปรดอดทน",
            recycling_tip3:"USDT Express Sell Central Return เวลา 12.00 น. และ 17.00 น. (ยกเว้นวันหยุดนักขัตฤกษ์หรือวันที่ไม่ใช่วันทำการ)",
        },
        public:{
            dt:"ข้อมูลบัญชีสาธารณะ",
            dd1:"ผู้รับเงิน",
            dd2:"บัญชีรับเงิน",
            dd3:"ชื่อธนาคาร",
            dd4:"ที่อยู่ธนาคาร",
            bankFn:"เงินดอลลาร์สหรัฐ",
        },
        dialog1:{
            title:"ซื้อ",
            h5:"ต้องอ่านก่อนชำระเงิน",
            p1:"ข้อสำคัญ: ข้อควรระวังในการโอนเงิน ห้ามสังเกตในการโอนเงิน",
            p2:"ข้อควรระวังในการโอนเงิน: อย่าสังเกตคำที่เกี่ยวข้องกับสกุลเงินเสมือนเช่น 'สกุลเงินเสมือน' และ 'USDT' เมื่อโอนเงินเพื่อป้องกันไม่ให้บัตรธนาคารของคุณถูกแช่แข็งและถูกสกัดกั้นการโอนเงิน",
            p3:"โอนเงินโดยใช้ชื่อที่ได้รับการรับรองด้วยชื่อจริงของบัญชี",
            p4:"ปล่อยเหรียญช้าลงเล็กน้อยในชั่วโมงเร่งด่วนโปรดอดทน",
            span1:"ปริมาณ",
            span2:"วงเงิน",
            span3:"ราคาต่อหน่วย",
            buynum:"กรุณาใส่จำนวนการซื้อ",
            allbuyFn:"ซื้อทั้งหมด",
            pay_for:"ชำระเงินจริง",
            comeFn:"ยืนยัน",
        },
        dialog2:{
            title:"เงินดอลลาร์สหรัฐ",
            label1:"จำนวนเงิน",
            placeholder1:"กรุณาใส่จำนวนเงิน",
            label2:"คูปอง",
            submitBank:"ส่ง",
        },
        rules:{
            message1:"กรุณาใส่จำนวนรวมของการขาย",
            message2:"กรุณาเลือกบัตรธนาคาร",
            message3:"กรุณาใส่จำนวนเงิน",
            message4:"กรุณาอัพโหลดคูปอง",
            err_message1:"กรุณาใส่จำนวนการซื้อ",
            err_message2:"จำนวนเงินที่ชำระจริงต้องอยู่ในช่วงที่กำหนด",
            err_message3:"มีการซื้อเกินจำนวนที่ถือครองอยู่",
        },

        buy:{
            h2:"ซื้อ USDT",
            span1:"ปริมาณ",
            span2:"ราคาต่อหน่วย",
            span3:"ชำระเงินจริง",
            item2_h:"วิธีการรับเงินจากผู้ขาย",
            item2_p1:"โปรดใช้ตัวเอง",
            item2_p2:"วิธีการชำระเงิน โอนเงินอัตโนมัติไปยังหมายเลขบัญชีต่อไปนี้",
            msg_h1:"ธนาคารผู้รับเงิน",
            msg_h2:"ชื่อสาขา",
            msg_h3:"บัญชีธนาคาร",
            msg_h4:"รหัสธนาคาร",
            msg_h5:"หมายเหตุการโอนเงิน",
            msg_h6:"อัพโหลดคูปอง",
            upload_text:"ลากไฟล์มาที่นี่หรือคลิกเพื่ออัพโหลด",
            upload_tip:"อัปโหลดไฟล์รูปภาพเท่านั้นและไม่เกิน 8 เมตร",
            submitFn:"ส่งบัตรกำนัล",
            dealers_img:"ดีลเลอร์",
            flag_no:"มีข้อมูลครบถ้วนอยู่แล้ว",
            placeholder:"โปรดป้อนเนื้อหาและกดปุ่ม 'Enter' เพื่อส่ง",
            message1:"กรุณากรอกข้อความหรือเลือกรูปภาพเพื่อส่ง",
            message2:"ส่งล้มเหลว",
        },
    },

    index:{
		guide_box:{
			item1:"ลงทะเบียน",
			item2:"รายการทอง",
			item3:"การซื้อขาย",
			item4:"การซื้อขาย",
			item1_text:"ลงทะเบียนเพื่อรับ 10USDT ทันที",
			item2_text:"ฝากเงิน รับ 20USDT ทันที",
			item3_text:"เทรดตอนนี้ รับ 50USDT",
			item4_text:"เทรดมากกว่า 10,000USDT รับ 100USDT ทันที",
		},
    },

    Help_center:"ศูนย์ช่วยเหลือ",
    account_fiat1:"บัตรธนาคาร",
    account_fiat2:"สั่งซื้อ French",
    account_fiat3:"USDT คำสั่งขายอย่างรวดเร็ว",

    account_fiat4:"สั่งซื้อเหรียญใหม่",

    account_bank:{
        placeholder0:"กรุณาเลือกสกุลเงิน",

        placeholder1:"กรุณากรอกชื่อผู้รับเงิน",
        placeholder2:"กรุณากรอกธนาคารผู้รับเงิน",
        placeholder3:"กรุณากรอกข้อมูลสาขา",
        placeholder4:"กรุณากรอกบัญชีรับเงิน",
        placeholder5:"กรุณากรอกรหัสธนาคาร",
        submitForm:"ส่ง",
        list_title:"รายการบัตรธนาคาร",
        list_span1:"ผู้รับเงิน",
        list_span2:"ชื่อธนาคาร",
        list_span3:"ข้อมูลสาขา",
        list_span4:"บัญชีรับเงิน",
        list_span5:"รหัสการโอนเงิน",
        list_span6:"การดำเนินงาน",
        onDel:"ลบ",
        message1:"กรุณากรอกชื่อผู้รับเงิน",
        message2:"กรุณากรอกธนาคารผู้รับเงิน",
        message3:"กรุณากรอกบัญชีรับเงิน",
        err_message1:"จำกัดจำนวนบัตรธนาคาร 10 ใบ",
        onDel_h:"การดำเนินการนี้จะลบบัตรธนาคารอย่างถาวร จะดำเนินต่อไปหรือไม่",
        onDel_tips:"เคล็ดลับ",
        confirmButtonText:"ระบุ",
        cancelButtonText:"การยกเลิก",
    },
    account_fiat:{
        title:"สั่งซื้อ French",
        select_placeholder:"กรุณาเลือกสถานะ",
        select_val1:"กำลังดำเนินการ",
        select_val2:"เสร็จสมบูรณ์",
        select_val3:"การสั่งซื้อล้มเหลว",
        col1:"หมายเลขการสั่งซื้อ",
        col2:"จำนวนเงิน",
        col3:"สกุลเงิน",
        col4:"ราคา",
        col5:"ทั้งหมด France Coin",
        col6:"สถานะ",
        status1:"กำลังดำเนินการ",
        status2:"เสร็จสมบูรณ์",
        status3:"การสั่งซื้อล้มเหลว",
        span_h1:"ชื่อผู้รับเงิน",
        span_h2:"ธนาคารผู้รับเงิน",
        span_h3:"ชื่อสาขา",
        span_h4:"บัญชีรับเงิน",
        span_h5:"รหัสธนาคาร",
        span_h6:"ที่อยู่ธนาคาร",
        span_h7:"หมายเหตุการโอนเงิน",
        span_h8:"สร้างเวลา",
        span_h9:"คูปอง",
    },
    account_recycle:{
        title:"USDT คำสั่งขายอย่างรวดเร็ว",
        select:"กรุณาเลือกสถานะ",
        select_val1:"กำลังดำเนินการ",
        select_val2:"เสร็จสมบูรณ์",
        select_val3:"การสั่งซื้อล้มเหลว",
        span1:"หมายเลขการสั่งซื้อ",
        span2:"จำนวนเงิน",
        span3:"สกุลเงิน",
        span4:"ราคา",
        span5:"ทั้งหมด France Coin",
        span6:"สถานะ",
        status1:"กำลังดำเนินการ",
        status2:"เสร็จสมบูรณ์",
        status3:"การสั่งซื้อล้มเหลว",
        span_h1:"ชื่อผู้รับเงิน",
        span_h2:"ธนาคารผู้รับเงิน",
        span_h3:"ชื่อสาขา",
        span_h4:"บัญชีรับเงิน",
        span_h5:"รหัสธนาคาร",
        span_h6:"ที่อยู่ธนาคาร",
        span_h7:"หมายเหตุการโอนเงิน",
        span_h8:"สร้างเวลา",
        span_h9:"คูปอง",
    },
    settings_password:{
        placeholder1:"กรุณากรอกรหัสผ่านเก่า",
        placeholder2:"กรุณากรอกรหัสผ่านใหม่",
        placeholder3:"กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
        submit:"ส่ง",
        message:"รหัสผ่านต้องกรอกทั้งสองครั้ง",
    },
    settings_paypassword:{
        placeholder1:"กรุณากรอกกล่องจดหมาย",
        placeholder2:"กรุณากรอกรหัสผ่านใหม่",
        placeholder3:"กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
        placeholder4:"โปรดป้อนรหัสยืนยันกล่องจดหมาย",
        submit:"ส่ง",
        send:"ส่งจดหมาย",
        message:"รหัสผ่านต้องกรอกทั้งสองครั้ง",
    },
    account_details:{
        router:"รายละเอียดกระเป๋าสตางค์สัญญา",
        span1:"ประเภท",
        span2:"สถานะ",
        span3:"จำนวนเงิน",
        span4:"เวลา",
        type0:"ซื้อขึ้น",
        type1:"ซื้อและลง",
        type2:"โอนเข้า",
        type3:"โอนออก",
        type4:"การตั้งถิ่นฐาน",
        type5:"ค่าธรรมเนียมการจัดการ",
        type6:"แก้ไขระบบ",
        type7:"การทำงานของระบบ",
        state0:"เพื่อดำเนินการ",
        state1:"เสร็จสิ้น",
        state2:"ล้มเหลว",
    },

    r:{
        r1:"ลงทะเบียนเพื่อเรียกดูและยินยอม",
        r2:"ข้อตกลงผู้ใช้",
    },

    new_list:{
        h:"ตลาดเหรียญใหม่",
        span2:"ราคาเสนอขาย",
        span3:"วันที่ออก",
    },
    issue:{
        onOrderShow:"เสนอซื้อ",
        issue_amount:"จำนวนการออก",
        use_issue_amount:"จำนวนการสมัคร",
        close_day:"จำนวนวันที่ปิด",
        label:"ปริมาณการซื้อ",
        placeholder:"กรุณากรอกจำนวนที่ต้องการซื้อ",
        Cancel:"การยกเลิก",
        Submit:"ส่ง",
        detail:"แนะนำรายละเอียด",
    },
    issue_order:{
        col1:"คู่เทรด",
        col2:"ราคาสมัคร",
        col3:"ปริมาณ",
        col4:"ยอดรวม",
        col5:"จำนวนวันที่ปิด",
        col6:"สร้างเวลา",
    },

    contract_shenqin:{
        text_notice1:'คุณยังไม่ได้ส่งการรับรองชื่อจริงโปรดส่งการรับรองชื่อจริงก่อน',
        text_notice2:'การรับรองชื่อจริงของคุณล้มเหลวโปรดส่งการรับรองชื่อจริงอีกครั้ง',
        text_notice3:'การรับรองชื่อจริงของคุณอยู่ระหว่างการตรวจสอบ',
        tips:'เคล็ดลับ',
        confirmButtonText:'ยืนยัน',
        cancelButtonText:'การยกเลิก',
        // confirm_text:'合約交易需要資產達到10000USDT以上，請提交申請',
        confirm_text:'กรุณาส่งใบสมัครสัญญา คลิกยืนยันการสมัครให้เสร็จสมบูรณ์ และสามารถทำการซื้อขายได้เมื่อสมัครสำเร็จ',
        dataVerify_text:'คำขออนุญาตการซื้อขายสัญญาของคุณอยู่ระหว่างการดำเนินการโปรดรอการดำเนินการ',
    },


    is_cancel_order:"แน่ใจนะว่าต้องการยกเลิกคำสั่ง",
    cancel_order:"การยกเลิกคำสั่งซื้อ",
    send_img:"อัพโหลดคูปอง",

    // 現貨新增市價限價
    youjia:"ซื้อขายในราคาที่ดีที่สุดในปัจจุบัน",
    shijia_chao:"เกินยอดคงเหลือที่มีอยู่",
    allBuyValueFnShijia_p:"จำนวนเงิน",
    allSellValueFnShijia_p:"ปริมาณ",

    xinbiyure:{
        issue_price:'ราคาเสนอขาย',
        issue_date:'วันที่ออก',
        issue_amount:'จำนวนการออก',
        guimo:'ขนาดการระดมทุน',
        use_issue_amount:'วงเงินซื้อสะสม',
        close_day:'วันที่แช่แข็ง',
        published_date:'วันที่ประกาศผลรางวัล',
        showToast1:"เกินวงเงินที่เสนอซื้อ",
        showToast1_rengou:"เกินวงเงินจองซื้อที่เหลือ",
        issue_plc:"กรุณากรอกจำนวนเงินที่ต้องการซื้อ",

        can_num_h:"วงเงินที่สามารถซื้อได้",
        orderFormData_amount1:"กรุณากรอกจำนวนเงินที่ต้องการซื้อ",
        can_num_h1:"เหลือวงเงินสมัครสมาชิก",
        orderFormData_amount2:"กรุณาใส่จำนวนเงินที่สมัครสมาชิก",

        // 新增可用餘額usdt（現貨）
        can_num_h_u:"ยอดคงเหลือที่มีอยู่",  
        showToast_u:"เกินยอดคงเหลือที่มีอยู่",

        span1:"คู่เทรด",
        span2:"จำนวนเงินที่เสนอซื้อ",
        span3:"สถานะ",
        span4:"วงเงินที่ชนะ",
        span5:"เหลือวงเงินสมัครสมาชิก",
        span6:"เวลาซื้อ",
        span7:"การดำเนินงาน",
        buyFn:"สมัครสมาชิก",
        state_arr1:"ในการเสนอซื้อ",
        state_arr2:"ชนะรางวัล",
        state_arr3:"เสร็จสิ้น",
        state_arr4:"เสร็จสิ้น",
        amount_message:"กรุณาใส่จำนวนเงินที่สมัครสมาชิก",
    },

    recharge_manage:{
        title:'ค่าธรรมเนียมการจัดการ Wallet'
    },

    money_error:{
        text1:'ไม่สามารถว่างได้',
        text2:'สามารถเก็บตำแหน่งทศนิยมได้เพียง 2 ตำแหน่ง',
    },
}