export default {
    b_buy:'Buy',
    b_sell:'Sell',

    c_buy:'Buy',
    c_sell:'Sell',

    n_detail:'Singula',
    n_more:'denuntiatio',

    currency_exchange_rate:'Currus exchange rate',
    submit: 'submittere',
	amount: 'moles',
	price: 'pretium',
	available: 'praesto',
	total: 'total',
	no_more: 'non plus',
	optional: 'ad libitum',
	remark: 'Animadverte',
	tips: 'Apicibus',
	copied: 'copied',
	petf: 'Please enter this field',
	MAX: 'maximum valorem',
    buyUp:"emere",
    buyDown:"emere descendit",
    look:"Check",
    id_image_a:"Quaeso imposuisti fronte photo tui ID card",
    id_image_b:"Quaeso imposuisti tergo photo tui ID card",
    rfields:"Requiritur agrorum",

    imageLimit:"Image File Size Limit 5MB",

    search_text:"Please enter the monetæ (eg: BTC)",
    registerS:'Adnotatione success',
    shouyi_t:'Totalis reditus',

    // 新加
    required_address0:'Registered address',
    required_address:'Please enter registered address',
    required_swift:'Please enter swift code',

    phoneVerified:'Contact information',
    phoneVerifiederr:'Please enter contact information',

    //公賬驗證
    corporate_account_power:{
        h_1:"Applicare pro USD ad Corporate Ratio comprobatio",
        p_1:"U.S. dollar deposits need to be verified by the public account before they can be operated",
        btn:"Submit",
        h_2:"admonitus",
        p_2:"USD ad publicam rationem verificationis subiecisti, placere exspecta approbatione",
    },

    //交易方向
    onJump1:"Macula Trading",
    onJump2:"contractus Trading",

    news_center:"News Center",

    middle_window:{
        Position:"Locus",
        Price:"pretium",
        Amount:"quantitas",
    },
    Confirm_order:{
        title:"Confírma Ordinis",
        h:"current pretium",
        Due_time:"Deadline (seconds)",
        Profit:"reditus",
        label1:"Moles investment (uno investment non minus quam X)",
        plc1:"Intra in investment moles",
        Expected_profits:"expectata lucrum",
        Guaranteed_amount:"guaranteed amount",
    },
    navbar: {
        home: 'ante paginam',
        subscribe: 'subscriptione',
        fund: 'Obstructionum Fund',
        help: 'auxilium',
        language: 'Latin',
        sign_in: 'Log in',
        my_assets: 'bonorum meorum',
        profile: 'personalis notitia',
        settings: 'extruxerat',
        logout: 'exscribe',

        new1:'Nova monetæ calefaciat',
        new2:'Quotes',
        new3:'Macula bona',
        new4:'contractus',
        new5:'Fiat monetæ',
        // new_new:"Novus Currency Quotes",
        new_new:"Active monetæ",
    },
    footer: {
        about_us: 'De nobis',
        help_center: 'Auxilium Center',
        fees: 'pertractatio fee',
        user_agreement: 'Termini servitii',
        contact_us: 'nobis loquere',
        copyright: 'All copyrights',
        qualification: 'Informationes absolute',
    },
    base: {
        back: 'reditus',
        buy: 'emptum',
        sell: 'vendere',
        no_more: 'inanis album',
        loading: 'loading',
        sending: 'mittens',
        pending: 'Processing',
        success: 'success',
        fail: 'Fail',
        warning: 'admonitus',
        danger: 'moneo',
        error: 'Error',
        email: 'Mail',
        password: 'password',
        old_pw: 'Vetus Password',
        new_pw: 'new password',
        re_pw: 'rursus intra password',
        pay_password: 'transaction password',
        submit: 'submittere',
        update: 'renovare',
        new: 'novus',
        remark: 'Animadverte',
        voucher: 'Documentorum',
        amount: 'moles',
        enter_usdt_quantily: 'PDF moles USDT',
        enter_trade_password: 'Please enter transaction password',
        login_first: 'Quaeso ini primo',
        state: 'status',
        profit: 'reditus',
        coin: 'monetæ',
        coin_profit: 'denarius reditus',
        refresh: 'reficite',
        total: 'total',
        deal: 'agunt'
    },
    home: {
        // banner: [
        //     require('@/assets/index/banner/1-1.jpg'),
        //     require('@/assets/index/banner/2-1.jpg'),
        //     require('@/assets/index/banner/3-1.jpg')
        // ],
        title1: 'Happycoinage',
        title2: 'celeriter rem tuta dignissim',
        opened: 'aperta',
        more: 'More',
        trade: 'commercium',
        pair: 'par negotiatione',
        price: 'tardus pretium',
        change: 'fluctuatio',
        high: 'altissimum',
        low: 'lowest',
        finished: 'fac a paciscor',
        deal: 'agunt',
        // service_title: '立即開始您的加密貨幣之旅',
        service_title: 'IV magna puncta venditionis',
        service1: {
            title: 'Certa ratio',
            des1: 'Curare salutem, stabilitatem et efficaciam rerum',
            des2: 'Electos quadrigis mundi summo talenta'
        },
        service2: {
            title: 'Securitas financial',
            des1: 'HTTPS data encryption, authenticas',
            des2: 'Repositio crumena frigida, architectura campestris oeconomus, multiplex tutela securitatis'
        },
        service3: {
            title: 'optimum experientia',
            des1: 'Recessus celeriter, multilingual',
            des2: 'Summus mercaturae engine perficientur, experientia celeriter negotiatione'
        },
        service4: {
            title: 'professionalem officium',
            des1: 'Lorem ministerium quadrigis',
            des2: 'Responde protinus ad user quaestiones'
        },
        guide_title:"Inceptor's Guide",
    },
    fund: {
        banner1: {
            title1: 'USDT Standard Perpetua Swap Free Bonus',
            title2: 'Millions praemiorum te exspectant ut ducatur'
        },
        banner2: {
            title1: 'Blockchain Fund',
            title2: 'Feliciter referre amicos ad excelsum USDT'
        },
        banner3: {
            title1: 'XMR、IOTA、AR、XRT、',
            title2: 'USDT-margine perpetuae contractus publice deductae'
        },
        newest: 'novissima',
        my_participation: 'Sum implicatus',
        notify_message: 'Macte felix emptio',
        incentive_mechanism: 'praemium ratio',
        locked_staking: 'Obfirmo ad earn denarios',
        ongoing: 'in progress',
        sold_out: 'super',
        countdown_starts:'Satus in V minuta',
        profit: 'reditus',
        days: 'caelum',
        user_limit: 'Circumscribere numerum users participating',
        applicants_user: 'Numerus applicatae users',
        buy_range: 'Participatio Aliquam dolor',
        read_more: 'vide details',
        purchase: 'participare',
        available: 'praesto',
        success_title: 'feliciter summitto',
        success_msg:'Bene submissum est, et countdown exitum nuntiare disrumpendi, participationem meam reprehendo',
        lock_up_the_rules: 'Lockup Rules',
        lock_up_the_rules_1: 'Eventus srumuporum annuntiabitur post numerum uniuscuiusque rotundi',
        lock_up_the_rules_2: 'Automatice reserare post cincinno-sursum temporis spatium',
        lock_up_the_rules_3: 'Reditus TAOGE explicatio: Totalis reditus USDT per realem tempus pretium TAOGE divisum est, et deinde in quantitatem TAOGE convertitur, quod totum vectigal TAOGE est.',
        lock_up_the_rules_4: 'Reditus USDT consecuti post obfirmationem ad rationem personalem transferentur, et reditus TAOGE constringuntur usque ad diem 30 Augusti 2021, post quem mercatura libera gelata esse potest.',
        project_introduction: 'Prooemium Introductio',
        project_introduction_content: 'Cur TAOGE fiscus stipes tam altae reditus habere potest? Nos reditus oeconomicos et quantitatis stabiles habemus, inter USDT alta frequentia saepiendi arbitragium trans mercatus internationales, quod directe administratur a Potestate Cloud Cryptal et priori CFO (Marianne Lake) de JPMorgan Chase. Turma media administratio plus quam XV annos experientiae practicae in re nummaria habet, et cum periculorum et countermensurarum saepiendi et arbitragii valde familiaris est et satis maturam quantitatis saepiendi consilium formavit.',
        lock_period: 'Nefas statum cyclum',
        lock_start_time: 'Lockup satus tempore',
        lock_end_time: 'Lockup finem temporis',
        state: {
            checking: 'sub review',
            locking: 'clausum',
            finished: 'integram emissionem',
            fail: 'Fail'
        }
    },
    subscribe: {
        title1: 'Novum Currency Subscription',
        title2: 'Inceptis stipendii opitulandis stipendiis operam dabimus, adjutoribus invenimus, ac novis ac iucundis notionibus ascendimus',
        new_currency: 'nova monetæ',
        my_participation: 'mea subscriptione',
        project_details: 'Project details',
        subscription_ratio: 'Ratio subscriptionis',
        issue_quota: 'Exitus quota',
        participating: 'participatur',
        lock_period: 'cincinno-sursum tempus',
        months: 'mensis',
        thaw_interval: 'Reneè spatium',
        hours: 'Hora',
        available: 'praesto',
        purchase_limit: 'Participatio cohibita artius',
        total: 'total',
        fee: 'pertractatio fee',
        get_involved_now: 'Nunc intromittas',
        end_date: 'finem date',
        project_des: 'Project Description',
        white_paper: 'albus paper'
    },
    login: {
        title: 'Member Login',
        dont_have_an_account:'Socius rationem nondum habes?',
        to_register: 'ut subcriptio',
        email: 'inscriptio electronica',
        password: 'login password',
        captcha: 'Captcha',
        click_me_show: 'Click to show',
        sign_in_now: 'log in statim',
        forget_password: 'Oblitusne es tesserae tuae ?',

        emailPhone:'Email vel mobile phone numerus',
        min6:'(minimum VI numeri)',
        rules1:'Please enter your email or mobile number',
        rules2:'Please enter in password of certe VI digitorum',
        rules3:'Please enter ad minimum VI digiti duplicatam password',
        rules3_2:'Tesserae ingressi bis non aequare',
        rules4:'Please enter the invitation code',
        rules5:'Registration requirit videndi et consentiendi ad User foederis',
        captcha_plc:'Please enter ad minimum IV digiti tesseram',
    },
    register: {
        title: 'Sign up',
        phone: 'series numerorum telephonica',
        re_password: 'Iterare password',
        invitation_code: 'Invitatio codice',
        email_captcha: 'E-mail tesseram',
        send_to_email: 'mittere email',
        already_have_an_account: 'Habesne iam rationem membrum?',
        sign_in: 'Log in'
    },
    forget: {
        title: 'reset password',
        retrieve_now: 'reset nunc'
    },
    account: {
        title: 'propter',
        recharge: 'recharge',
        profile: 'personalis notitia',
        my_assets: 'bonorum meorum',
        withdraw: 'recedere',
        transactions: 'Macula Trading',
        micro_contract: 'Contractus Micro transactions',
        contract:'contractus transaction',
        inviting_and_awards: 'Invitationes et adipisicing elit',
        settings: 'extruxerat',
        list_of_assets: 'Asset List',
        available: 'praesto',
        frozen: 'duratus',
        available_log: 'Available records',
        event: 'eventus',
        time: 'tempus',
        event_type: {
            recharge: 'recharge',
            take: 'deduct',
            withdraw: 'recedere',
            withdraw_fail: 'Recessus defecit',
            trade_buy: 'Macula buy',
            trade_sell: 'Macula vendere',
            trade_success: 'felix res',
            trade_cancel: 'Transactio receptae',
            subscribe: 'subscriptione',
            contract_buy: 'Micro-contractus ordo',
            contract_back: 'Micro contractus redit',
            contract_win: 'Contractus lucri Micro-',
            frozen: 'duratus',
            thaw: 'CALEFACTO',
            fund_buy: 'obstructionum fiscus buy',
            fund_buy_fail: 'Fund Buy obstructionum Deficio',
            fund_back: 'Obstructionum Fund principali Redi',
            fund_income: 'Fund deducuntur obstructionum',
            cfl1ua: 'Permanens recta dis praemium',
            cfl1u: 'Direct dis praemium',
            cfl2u: 'secundarium praemium',
            cfl3u: 'III ORDO Praemia',
            exchange: 'Flash commutationem',

            // 新增
            new25:'Transferre ad rationem contractus',
            new26:'Transferre contractus rationem',
            new27:'Fiat monetæ recharge',
            new28:'Fiat recedere',
            new29:'Novum Currency Subscription',
            new30:'Incentivum pecunia',
            new31:'ministerium crimen',
        },
        email: 'inscriptio electronica',
        verify: 'Certification',
        verify_state: {
            unsubmitted: 'unsubmitted',
            fail: 'Fail',
            checking: 'pendente review',
            success: 'perago'
        },
        verify_page:{
            rules1:'Please enter ID card nomen tuum',
            rules2:'Quaeso intra ID numerus',
        },
        addressTitle:'frigus pera oratio',
    },
    wallet: {
		tb: 'Totalis bonorum equivalent',
		recharge: 'deposit',
		withdraw: 'recedere',
		exchange: 'Flash commutationem',
		in_order: 'duratus',
		value: 'involutus',
		pea: 'Please enter the amount',
		petp: 'Please enter the fiscus password',
		log_type: [
			'recharge',
			'deduct',
			'recedere',
			'Recessus defecit',
			'commercia buy',
			'commercia vendunt',
			'felix res',
			'Transactio receptae',
			'emere nummos',
			'contractus negotiatione emptum',
			'Contractus artis ducatur',
			'Wins Contractus Trading',
			'systema Frigidus',
			'Systema Thaw',
			'Acceperunt Fund Buy',
			'Thochen Fund Buy Deficio',
			'Thochen Fund Releases Principal',
			'Acceperunt Fund deducuntur',
			'Permanens direct dis diminuere',
			'Recta dis diminuere',
			'secundarium diminuere',
			'ORDO III diminuere',
			'Flash commutationem',
			'Flash commutationem',
			'Flash commutationem'
		]
	},
    recharge: {
		title: 'deposit',
		cc: 'Click to effingo',
		uv: 'documentorum eget sollicitudin',
		puv: 'Imposuisti documentorum',
		nor: 'Non aperta ad recharge',
		history: {
			title: 'Depositum recordum',
			checking: 'pendente review',
			success: 'bene ratio',
			fail: 'Fail'
		},
		tips: {
			USDT: [
				'Placere eligere retis ERC20/OMNI/TRC20 et USDT ad inscriptionem superiorem subtrahe, alioquin res non recuperabuntur. Postquam ad electronicam superius deposuimus, nodi retis totam confirmare debes, rationem confirmare post confirmationes 12 retiaculas, ac receptam confirma post confirmationes 12 retiaculas.',
				'Depositum minimum moles 200USDT est, et depositum infra quantitatem depositi minimum non credi potest nec retribui potest. Inscriptio tua recharge saepe non mutabit. Si aliqua mutatio est, recharge potes et certiorem te per nuntium electronicum aut electronicum notificabimus.',
				'Quaeso serva computatrum tuum et navigare tutum ne informationes corruptas vel emanatas.'			
			],
			BTC: [
				'Placere eligere retis ERC20/OMNI/TRC20 et BTC ad inscriptionem superiorem subtrahe, alioquin res non recuperabuntur. Postquam ad electronicam superius deposuimus, nodi retis totam confirmare debes, rationem confirmare post confirmationes 12 retiaculas, ac receptam confirma post confirmationes 12 retiaculas.',
				'Depositum minimum moles 200USDT est, et depositum infra quantitatem depositi minimum non credi potest nec retribui potest. Inscriptio tua recharge saepe non mutabit. Si aliqua mutatio est, recharge potes et certiorem te per nuntium electronicum aut electronicum notificabimus.',
				'Quaeso serva computatrum tuum et navigare tutum ne informationes corruptas vel emanatas.'			
			],
			ETH: [
                'Placere eligere retis ERC20/OMNI/TRC20 et ETH ad inscriptionem superiorem subtrahe, alioquin res non recuperabuntur. Postquam ad electronicam superius deposuimus, nodi retis totam confirmare debes, rationem confirmare post confirmationes 12 retiaculas, ac receptam confirma post confirmationes 12 retiaculas.',
				'Depositum minimum moles 200USDT est, et depositum infra quantitatem depositi minimum non credi potest nec retribui potest. Inscriptio tua recharge saepe non mutabit. Si aliqua mutatio est, recharge potes et certiorem te per nuntium electronicum aut electronicum notificabimus.',
				'Quaeso serva computatrum tuum et navigare tutum ne informationes corruptas vel emanatas.'			
			],
			LTC: []
		}
	},
    withdraw: {
		title: 'recedere',
		now: 'Non aperta ad receptum angustias',
        swa: 'Lego pera inscriptio',
        awa: 'Adde pera oratio',
        nwa: 'nova pera oratio',
		history: {
			title: 'Recessus recordum',
			checking: 'pendente review',
			success: 'success',
			fail: 'Fail',
			cancel: 'Cancel'
		}
	},
    exchange: {
		title: 'Flash commutationem',
		history: {
			title: 'Flash commutationem record'
		}
	},
    share: {
		title: 'participes cum amicis',
		cic: 'Effingo quod invitatio codice',
		t: 'Cautionum Fund Promotio et Praemium Socius Mechanismum',
		t1: 'recta ipsum propellente',
		t1_1: '1. Recta promovere minus quam II populus ad II% USDT praemium transactionis turnover',
		t1_2: '2. Directe promovere plus quam III populus (including III populus) ad IV% USDT praemium transactionis turnover',
		t1_3: '3. Directe promovere plus quam V populus (including populus V) ad VI% USDT praemium transactionis turnover',
		t1_4: '4. Directe promovere plus quam X populus (including X populus) ad VIII% USDT praemium transactionis turnover',
		t2: 'secundarium agente',
		t2_1: '1. Si minus quam 4 secundariae agentes sunt, 1% USDT praemium transactionis turnover possidebit',
		t2_2: '2. Cum secundo-gradu agentis ad 4 (including 4), recipies praemium 2% USDT pro fluxu transactionis',
		t2_3: '3. Cum secundo gradu agentis ad 6 (including 6), recipies praemium 4% USDT pro negotio turnover',
		t3: 'tertiarius agentis',
		t3_1: '1. Cum tertius gradus agentis ad 1 personam pervenit, praemium 3% USDT transactionis turnover erit',
		t3_2: '2. Tertius gradus agentis pervenit 4 homines (including 4 people), and gets a 6% USDT praemium transactionis turnover',
		t3_3: '3. Tertius gradus agentis ad VI homines (including VI populus), et accipit VIII% USDT praemium transactionis turnover',
		team: {
			title: 'Invitatio ad bibendum diminuere',
			ct1: 'Totalis numerus invitees',
			ct2: 'Numerus invitees hodie',
			ct3: 'Earnings hodie',
			ct4: 'Totalis reditus',
			ct5: 'summa statera',
			level_1: 'Level 1', 
			level_2: 'Level 2', 
			level_3: 'Level 3',
		}
	},
    user: {
		email: 'inscriptio electronica',
		email_captcha: 'E-mail tesseram',
		captcha: 'Imago verificationis',
		pwd: 'password',
		confirm_pwd: 'Adfirmare Password',
		trade_pwd: 'Fund password',
		confirm_trade_pwd: 'Adfirmare fiscus password',
		wa: 'pera oratio',
		peea: 'Quaeso initus est inscriptio electronica',
		pewa: 'Please enter inscriptionem peram',
		pec: 'PDF tesseram imaginem',
		peec: 'Please enter inscriptio tesseram',
		cfii: 'In graphic verificationis forma componitur ex litteris et numeris',
		ecfii: 'Inscriptio tesseram constat ex numeris',
		eafii: 'Forma errore electronica',
		pep: 'Please enter password',
		pecp: 'Quaeso confirmetis es tesserae',
		p_diff: 'Duo ingressi passwords non aequare',
		pfii: 'Tessera componitur ex litteris, numeris et symbolis',
		uff: 'Deficio ad upload lima'
	},
    contact_about:{
        label1:'contractus ordo',
        top_text_h1:'rete dignum',
        top_text_h2:'Margin',
        top_text_h3:'Position reditus',
        available:'Available Libra',
        fundswap:'Fund PERMUTO',
        form:'form',
        to:'to',
        pair:'par monetæ',
        num:'quantitas',
        num_plc:'PDF quantitatem',
        all:'omnis',
        cancel:'Cancel',
        confirm:'Certus',
        margin:'Margin',
        margin1:'Remaining margin',
        lever:'vectis',
        please_choose:'placet eligere',
        contract_order:'contractus ordo',
        position_order:'aperta positio ordinis',
        current_commission:'current mandatum',
        switchBtn:'Specta ad hodiernam genus',
        liquidation:'claudere locum',
        table1:{
            pair:'par monetæ',
            type:'type',
            income:'reditus',
            rate_return:'rate reditus',
            deal:'commercium',

            span_h1:'commissionis pretium',
            span_h2:'foramen pretium',
            span_h3:'Margin',
            span_h4:'Tardus pretium',
            span_h5:'Commissio tempus',
            span_h6:'foramen tempus',
            span_h7:'vectis',

            span_h8:'Closing price',
            span_h9:'Closing time',

            service_charge:'Service charge',
        },
        table2:{
            type:'type',
            price:'pretium',
            amount:'quantitas',
            time:'tempus',
            deal:'commercium',

            service_charge:'ministerium crimen',
        },
        recharge_text1:"USDT macula pera",
        recharge_text2:"USDT contractus pera",
        error1:'PDF quantitatem et non potest esse maior quam statera',
    },
    recharge_new:{
        verified_tip:'Quaeso transiet ad authenticas ante utens recharge munus',
        table_item1:'ordo numerus',
        table_item2:'status',
        table_item3:'type',
        table_item4:'moles',
        table_item5:'libellum',
        table_item6:'tempus',

        table_item7:'oratio',

        table_state1:'submittendo',
        table_state2:'success',
        table_state3:'Fail',

        amount_mes:'Purus numeri cum usque ad 2 decimales locis',
        amount_mes2:'Purus numeri cum usque ad 8 decimales locis',
        message_img:'Quaeso imposuisti imago auctor',
        message_password:'Transactio ignoro non vacua',
        message_bal:'Recessus moles non potest esse maior quam statera',

        table_item_new1:'Transferentia moneta',
        table_item_new2:'Transferentem quantitatem',
        table_item_new3:'Monetas receptae',
        table_item_new4:'Quantitas accepit',
    },
    address:{
        title:'frigus pera oratio',
        wallet_address:'Please enter the cold pera electronica',
        wallet_list_title:'Frigus pera album',
        wallet_type:'type',
        wallet_address_title:'frigus pera oratio',
        wallet_opea:'agunt',
        wallet_cancel:'Cancel',
        wallet_err:'Pera oratio non vacua',
        wallet_tip:'Limit X frigus reticulis',
    },
    //現貨訂單列表
    transactions:{
        span1:'type',
        span2:'Par / loci monetæ',
        span3:'commissionis pretium',
        span4:'quantitas',
        span5:'finalis pretium',
        span6:'summa pretium',
        span7:'tempus',
        span8:'status',

        cancelBtn:'ut irritum',

        state_new1:'committo ordinem',
        state_new2:'User revocatio',
        state_new3:'Transactio ordinis',
        state_new4:'Ratio undo',
    },
    micro_contract:{
        span1:'type',
        span2:'Par / loci monetæ',
        span3:'quantitas',
        span4:'tempus / cede',
        span5:'reditus',
        span6:'Incoluimus Price',
        span7:'sedes tempus',
        span8:'status',

        state1:'Eviewray',
        state2:'success',
        state4:'Fail',
    },

    // 現貨詳情
    trading_index:{
        el_title:'macula ordinem',
        el_title1:'ordo commissus',
        el_switch1:'Specta ad hodiernam genus',
        message1:'Totalis pretium emptio non potest excedere statera',
        message2:'Buy quantitas non potest excedere statera',

        // 新加市價限價
        tab_1:'Forum ordinem',
        tab_2:'Terminus ordo',
    },

    user_contract:{
        placeholder1:'placet eligere',
        span1:'revocet',
        span2:'User revocatio',
        span4:'clausa',
        span5:'Ratio undo',
        rentext1:'omnes currencies',
        rentext2:'Omnia genera',
        rentext3:'buy ordinem',
        rentext4:'vendere ordinem',
        rentext5:'omnes status',
        rentext6:'committo ordinem',
        rentext7:'User revocatio',
        rentext8:'fac a paciscor',
        rentext9:'claudere locum',
        rentext10:'Ratio undo',
    },

    //個人設置導航
    settings:{
        nav1:'KYC certificationem',
        nav2:'login password',
        nav3:'transaction password',
        nav4:'adnotatione record',
        ipAddress:"Disputatio",
        Date_time:"tempus",
    },

    fiat_currency:{
        message:{
            tips:'admonitus',
            p_userToken:'Quaeso ini primo',
            p_userVerified:'Quaeso transi realis-nomen authenticas prima',
            confirm:"confirmo",
            cancel:"Cancel",
        },
        onTab1:"ad libitum transaction",
        onTab2:"USDT quick sell",
        onTab3:"U.S. dollar deposit",
        onTab4:"Pecunia legitima",
        span1:"Venalitii",
        span2:"quantitas",
        span3:"limit",
        span4:"unitas pretium",
        span5:"solucionis methodo",
        span6:"commercium",
        span7:"pertractatio fee",
        bank:"Bank card",
        open:"emptum USDT",

        fait_pay:{
            inp1:'Recarge quanta（USDT）',
            plc1:'Per favor, introduce the recharge amount',
            inp2:'Quanta pagantia',
            plc2:'Automatically calculate payment amount',
        },

        recycle:{
            inp1:"Quantitas totalis vendidit",
            plc1:"Quantitas totalis vendidit",
            inp2:"monetæ",
            plc2:"Placere eligere monetæ",
            inp3:"summa copia vendidit",
            plc3:"summa copia vendidit",
            inp4:"Bank card",
            plc4:"Placere eligere ripam card",
            submitForm:"submittere",
            recycling_tips_title:"peculiari attentione items",
            recycling_tip1:"Quaeso utere suggestu ad signo authenticitatis genuinum nomen ripam card. Payee nomen repugnat cum tabulae inscripti nomen et reparari non potest.",
            recycling_tip2:"In summo ordine periodi, ob multitudinem ordinum discursum, licet longum tempus colligere solutionem, sis patiens.",
            recycling_tip3:"The centralized collection time of USDT express sale is 12.00AM and 05:00PM (except statutory holidays or non-working days).",
        },
        public:{
            dt:"Publica ratio notitia",
            dd1:"payee",
            dd2:"Accounts receptibilis",
            dd3:"Nomen ripae",
            dd4:"Bank oratio",
            bankFn:"U.S. dollar deposit",
        },
        dialog1:{
            title:"Buy",
            h5:"Priusquam legere debet solvente",
            p1:"Important: Notae de translationibus Quaeso notas fac notas cum pecunia transferendis.",
            p2:'Notae de translatione: Noli notare "monetam virtualem", "USDT" aliaque verba ad virtualem monetam transferentem pertinentia, ad vitandum chartam tuam constringi et transferre intercipi.',
            p3:"Cardam argentariam utere sub nomine authenticas realis-nominis rationis ad pecuniam transferendam.",
            p4:"Per horas apicem, emissio nummorum paulo tardius est, quaeso, perfer.",
            span1:"quantitas",
            span2:"limit",
            span3:"unitas pretium",
            buynum:"PDF quantitatem emptionis",
            allbuyFn:"emere omnia",
            pay_for:"verum solucionis",
            comeFn:"confirmo",
        },
        dialog2:{
            title:"U.S. dollar deposit",
            label1:"moles",
            placeholder1:"Please enter the amount",
            label2:"libellum",
            submitBank:"submittere",
        },
        rules:{
            message1:"PDF quantitatem totius venditionis",
            message2:"Placere eligere ripam card",
            message3:"Please enter the amount",
            message4:"Imposuisti documentorum",
            err_message1:"PDF quantitatem emptionis",
            err_message2:"In ipsa solutione quantum debet esse intra terminum range",
            err_message3:"Numerus emit numerum possessionum excedit",
        },

        buy:{
            h2:"emptum USDT",
            span1:"quantitas",
            span2:"unitas pretium",
            span3:"verum solucionis",
            item2_h:"Venditor mercedem modum",
            item2_p1:"Quaeso uti te ipsum",
            item2_p2:"solucionis methodo sponte transfertur sequenti ratione",
            msg_h1:"Beneficiarius Bank",
            msg_h2:"genere nomen eius",
            msg_h3:"Bank rationes",
            msg_h4:"Bank Code",
            msg_h5:"Remittentiae Praecipua",
            msg_h6:"upload libellum",
            upload_text:"Fasciculi hic extrahuntur, vel preme fasciculos",
            upload_tip:"Tantum imaginum imaginum fasciculi impositi esse possunt, nec plus quam 8m",
            submitFn:"Submit documentorum",
            dealers_img:"Venalitii",
            flag_no:"Actum data",
            placeholder:"Please enter the content and press the 'Intra' key to submit",
            message1:"Please enter text or select an image to send",
            message2:"Non mittet",
        },
    },

    index:{
		guide_box:{
			item1:"mandare",
			item2:"deposit",
			item3:"commercium",
			item4:"commercium",
			item1_text:"Sign up to get 10USDT now",
			item2_text:"Deposit and get 20USDT instantly",
			item3_text:"Trade to get 50USDT now",
			item4_text:"Get 100USDT instantly when you trade over 10,000USDT",
		},
    },

    Help_center:"Auxilium Center",
    account_fiat1:"Bank card",
    account_fiat2:"Fiat ordo",
    account_fiat3:"USDT Express Sell Order",

    account_fiat4:"Nova Currency Purchase Ordinis",

    account_bank:{
        placeholder1:"Please enter payee",
        placeholder2:"PDF ripam beneficiarii",
        placeholder3:"Please enter genere notitia",
        placeholder4:"PDF acceptionem rationem",
        placeholder5:"PDF ripam codice",
        submitForm:"submittere",
        list_title:"Index ripae pecto",
        list_span1:"payee",
        list_span2:"Nomen ripae",
        list_span3:"Germen Information",
        list_span4:"Accounts receptibilis",
        list_span5:"translatio codice",
        list_span6:"agunt",
        onDel:"delere",
        message1:"Please enter payee",
        message2:"PDF ripam beneficiarii",
        message3:"PDF acceptionem rationem",
        err_message1:"Ripae card modum de 10",
        onDel_h:"Haec operatio chartam delebit in perpetuum, visne permanere?",
        onDel_tips:"admonitus",
        confirmButtonText:"Certus",
        cancelButtonText:"Cancel",
    },
    account_fiat:{
        title:"Fiat ordo",
        select_placeholder:"Placere eligere status",
        select_val1:"in progress",
        select_val2:"complebitur",
        select_val3:"ut defecit",
        col1:"ordo numerus",
        col2:"moles",
        col3:"monetæ",
        col4:"pretium",
        col5:"Fiat vehicula",
        col6:"status",
        status1:"in progress",
        status2:"complebitur",
        status3:"ut defecit",
        span_h1:"Payee nomen",
        span_h2:"Beneficiarius Bank",
        span_h3:"genere nomen eius",
        span_h4:"Accounts receptibilis",
        span_h5:"Bank Code",
        span_h6:"Bank oratio",
        span_h7:"Remittentiae Praecipua",
        span_h8:"tempus creatio",
        span_h9:"libellum",
    },
    account_recycle:{
        title:"USDT Express Sell Order",
        select:"Placere eligere status",
        select_val1:"in progress",
        select_val2:"complebitur",
        select_val3:"ut defecit",
        span1:"ordo numerus",
        span2:"moles",
        span3:"monetæ",
        span4:"pretium",
        span5:"Fiat vehicula",
        span6:"status",
        status1:"in progress",
        status2:"complebitur",
        status3:"ut defecit",
        span_h1:"Payee nomen",
        span_h2:"Beneficiarius Bank",
        span_h3:"genere nomen eius",
        span_h4:"Accounts receptibilis",
        span_h5:"Bank Code",
        span_h6:"Bank oratio",
        span_h7:"Remittentiae Praecipua",
        span_h8:"tempus creatio",
        span_h9:"libellum",
    },
    settings_password:{
        placeholder1:"Please enter vetus password",
        placeholder2:"Please enter a new password",
        placeholder3:"Please enter new password again",
        submit:"submittere",
        message:"Tesseras bis idem erit",
    },
    settings_paypassword:{
        placeholder1:"obsecro initus tuum inscriptio",
        placeholder2:"Please enter a transaction password",
        placeholder3:"Please enter a transaction password again",
        placeholder4:"Please enter inscriptio tesseram",
        submit:"submittere",
        send:"mittere email",
        message:"Tesseras bis idem erit",
    },
    account_details:{
        router:"Contractus pera singula",
        span1:"type",
        span2:"status",
        span3:"moles",
        span4:"tempus",
        type0:"emere",
        type1:"emere descendit",
        type2:"transfer in",
        type3:"transfer e",
        type4:"sedes",
        type5:"pertractatio fee",
        type6:"System leveling",
        type7:"Operatio systemae",
        state0:"Eviewray",
        state1:"perago",
        state2:"Fail",
    },

    r:{
        r1:"Registration requirit pasco et consentientes",
        r2:"User foederis",
    },

    new_list:{
        h:"Novus Currency Quotes",
        span2:"proventus pretio",
        span3:"exitus diem",
    },
    issue:{
        onOrderShow:"Subscription",
        issue_amount:"numerus agitur",
        use_issue_amount:"Subscription moles",
        close_day:"clausis diebus",
        label:"Quantitas emptio",
        placeholder:"PDF quantitatem",
        Cancel:"Cancel",
        Submit:"submittere",
        detail:"Singula descriptio",
    },
    issue_order:{
        col1:"par negotiatione",
        col2:"Subscriptione pretio",
        col3:"quantitas",
        col4:"Summa",
        col5:"clausis diebus",
        col6:"tempus creatio",
    },

    contract_shenqin:{
        text_notice1:'You have not submitted real-name authentication, please submit real-name authentication first',
        text_notice2:'Your real-name authentication failed, please re-submit real-name authentication',
        text_notice3:'Your real-name authentication is under review',
        tips:'Tips',
        confirmButtonText:'Confirm',
        cancelButtonText:'Cancel',
        // confirm_text:'Contract transactions require assets to reach more than 10,000 USDT, please submit an application',
        confirm_text:'Subtite, quaeso, aplicationem contractum, cliccate confirmare ut impletis aplicationem, et cum successus sit aplicatio, procedere potestis transactionem',
        dataVerify_text:'Your application for contract trading permission is being processed, please wait for it to be processed',
    },

    is_cancel_order:"Visne vero hunc ordinem rescindere?",
    cancel_order:"Cancel",
    send_img:"Upload voucher",

    // 現貨新增市價限價
    youjia:"Commercia ad hodiernam optimum pretium",
    shijia_chao:"Praesto exceditur statera",
    allBuyValueFnShijia_p:"Money",
    allSellValueFnShijia_p:"Amount",

    xinbiyure:{
        issue_price:'Issue price',
        issue_date:'Issue date',
        issue_amount:'Issue number',
        guimo:'Fundraising scale',
        use_issue_amount:'Cumulative purchase quota',
        close_day:'Freeze days',
        published_date:'Announcement date of the lottery',
        showToast1:"Exceed the available purchase limit",
        showToast1_rengou:"Exceed the remaining subscription amount",
        issue_plc:"Please enter the purchase amount",

        can_num_h:"Amount available for purchase",
        orderFormData_amount1:"Please enter the purchase amount",
        can_num_h1:"Remaining subscription quota",
        orderFormData_amount2:"Please enter the subscription amount",

        // 新增可用餘額usdt（現貨）
        can_num_h_u:"Available Libra",  
        showToast_u:"Praesto exceditur statera",

        span1:"Trading pair",
        span2:"Subscription amount",
        span3:"State",
        span4:"Lottery amount",
        span5:"Remaining subscription quota",
        span6:"Subscription time",
        span7:"Operate",
        buyFn:"Subscribe",
        state_arr1:"Subscribing",
        state_arr2:"Victores",
        state_arr3:"Finish",
        state_arr4:"Finish",
        amount_message:"Please enter the subscription amount",
    },

    recharge_manage:{
        title:'Management fee wallet'
    },

    money_error:{
        text1:'Non potest vacuum esse',
        text2:'Only two decimal places can be retained',
    },
}